import React from 'react'
import TopBar from './TopBar'
import avatar from '../assets/images/e.png'

const User = () => {
  return (
    <div class="card hover:shadow-none flex flex-col shadow-lg">
          <TopBar/>
          <div class="px-72 ml-40 profile flex m-3 ml-4 text-white absolute top-0">
            <img class="h-60 p-2 bg-white border border-black rounded-full" src={avatar} alt=""/>
            <div class="title mt-28 py-6 ml-3 text-black font-bold flex flex-col">
              <h1>Bill Van Demister</h1>
              <h3>Vandemister0@gmail.com</h3>
            </div>
          </div>
    </div>
  )
}

export default User