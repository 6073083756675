import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PaymentOptionCard from "../components/PaymentOptionCard";
import mmpesalogo from "../assets/images/mobilemoney/mmpesa.png";
import mtnlogo from "../assets/images/mobilemoney/mtn.png";
import airtellogo from "../assets/images/mobilemoney/airtel.png";
import ecocashlogo from "../assets/images/mobilemoney/ecocash.png";
import orangelogo from "../assets/images/mobilemoney/orange.png";
import vodacommpesalogo from "../assets/images/mobilemoney/vodacommpesa.png";
import tgopesalogo from "../assets/images/mobilemoney/tgopesa.png";
import halotellogo from "../assets/images/mobilemoney/halotel.png";
import DPO from "../assets/images/mobilemoney/DPO-Pay-Logo2.webp";
import pawapay from "../assets/images/mobilemoney/pawapay.png";
import tnmlogo from "../assets/images/mobilemoney/tnm.png";
import payment from "../api/payment";
import Swal from "sweetalert2";
import { Bars } from "react-loader-spinner";
import ReactLoading from 'react-loading';

const MobileMoney = () => {
  const [loading, setLoading] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const price = urlParams.get("price");
  const description = urlParams.get("description");

  const paymentOptions = [
    {
      id: 1,
      name: "TNM",
      description: "Pay using TNM Mpamba",
      image: tnmlogo,
      visible: true,
    },
    {
      id: 2,
      name: "Airtel MW",
      description: "Pay using Airtel Money.",
      image: airtellogo,
      visible: true,
    },
    // Add other payment options here...
  ];

  const handlePaymentOptionClick = async (option) => {
    const phoneNumberPrefix = option.name === "Airtel MW" ? "+26599" : "+26588";

    const { value: phoneNumber } = await Swal.fire({
      title: "Enter Phone Number",
      input: "text",
      inputLabel: "Enter your phone number",
      inputValue: phoneNumberPrefix,
      inputPlaceholder: "Phone number",
      showCancelButton: true,
      confirmButtonText: "Pay",
      cancelButtonText: "Cancel",
      inputValidator: (value) => {
        if (!value) {
          return "Phone number is required";
        }
      },
    });

    if (phoneNumber) {
      setLoading(true);

      const requestData = {
        userid: userId,
        price: price.toString(), // Convert price to string
        description: description,
        payment_gateway: "PawaPay",
        phone_number: phoneNumber,
        gateway_mode: "indirect"
      };

      try {
        console.log("Sending subscription request with data:", requestData);
        // Assuming payment.makePayment is a valid function
        await payment.makePayment(requestData);

        setLoading(false);
        setRequestCompleted(true);
      } catch (error) {
        console.error("Error while processing subscription:", error);
        // Show error message
      } finally {
        setLoading(false);
      }
    } else {
    }
  };

  const visiblePaymentOptions = paymentOptions.filter(
    (option) => option.visible
  );

return (
  <div className="bg-black">
    <div className="payment-page pr-14 pl-14 text-white mt-2">
      <div className="payment-page-header mb-8 text-center">
        {loading ? (
          <div className="flex flex-col items-center mt-4">
            <ReactLoading type='spinningBubbles'  color="#FFFFFF" height={90} width={50} />
            <div className="text-center text-white mt-.5">Payment is being processed, please confirm on your mobile phone..</div>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {visiblePaymentOptions.map((option) => (
              <div
                key={option.id}
                className="shadow-lg rounded-2xl bg-white dark:bg-gray-800 p-4"
              >
                <div className="flex-row gap-4 flex justify-center items-center">
                  <div className="flex-shrink-0">
                    <a href="#" className="relative block">
                      <img
                        alt="profile"
                        src={option.image}
                        className="mx-auto object-cover rounded-full h-16 w-16"
                      />
                    </a>
                  </div>
                  <div className="flex flex-col w-full">
                    <span className="text-lg font-medium text-gray-600 dark:text-white">
                      {option.name}
                    </span>
                    <span className="text-xs text-gray-400 w-46 mb-3">
                      {option.description}
                    </span>
                    <button
                      type="button"
                      className="py-2  px-4 bg-green-600 hover:bg-green-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg mt-auto"
                      onClick={() => handlePaymentOptionClick(option)}
                    >
                      Pay
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {requestCompleted && (
        <div className="text-center text-black">Payment successful!</div>
      )}
    </div>
  </div>
);

};

export default MobileMoney;
