import React, { useState, useEffect } from "react";
import logo from "../assets/images/image 8.png";
import background from "../assets/images/background.png";
import { useNavigate, Link } from "react-router-dom";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import "react-phone-number-input/style.css";
import AuthService from "../services/auth.service";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";

const countries = [
  //Africa
  { value: "DZ", label: "Algeria", flag: "🇩🇿", code: "+213" },
  { value: "AO", label: "Angola", flag: "🇦🇴", code: "+244" },
  { value: "BJ", label: "Benin", flag: "🇧🇯", code: "+229" },
  { value: "BW", label: "Botswana", flag: "🇧🇼", code: "+267" },
  { value: "BF", label: "Burkina Faso", flag: "🇧🇫", code: "+226" },
  { value: "BI", label: "Burundi", flag: "🇧🇮", code: "+257" },
  { value: "CM", label: "Cameroon", flag: "🇨🇲", code: "+237" },
  { value: "CV", label: "Cape Verde", flag: "🇨🇻", code: "+238" },
  { value: "CF", label: "Central African Republic", flag: "🇨🇫", code: "+236" },
  { value: "TD", label: "Chad", flag: "🇹🇩", code: "+235" },
  { value: "KM", label: "Comoros", flag: "🇰🇲", code: "+269" },
  {
    value: "CD",
    label: "Democratic Republic of the Congo",
    flag: "🇨🇩",
    code: "+243",
  },
  { value: "DJ", label: "Djibouti", flag: "🇩🇯", code: "+253" },
  { value: "EG", label: "Egypt", flag: "🇪🇬", code: "+20" },
  { value: "GQ", label: "Equatorial Guinea", flag: "🇬🇶", code: "+240" },
  { value: "ER", label: "Eritrea", flag: "🇪🇷", code: "+291" },
  { value: "SZ", label: "Eswatini", flag: "🇸🇿", code: "+268" },
  { value: "ET", label: "Ethiopia", flag: "🇪🇹", code: "+251" },
  { value: "GA", label: "Gabon", flag: "🇬🇦", code: "+241" },
  { value: "GM", label: "Gambia", flag: "🇬🇲", code: "+220" },
  { value: "GH", label: "Ghana", flag: "🇬🇭", code: "+233" },
  { value: "GN", label: "Guinea", flag: "🇬🇳", code: "+224" },
  { value: "GW", label: "Guinea-Bissau", flag: "🇬🇼", code: "+245" },
  { value: "CI", label: "Côte d'Ivoire", flag: "🇨🇮", code: "+225" },
  { value: "KE", label: "Kenya", flag: "🇰🇪", code: "+254" },
  { value: "LS", label: "Lesotho", flag: "🇱🇸", code: "+266" },
  { value: "LR", label: "Liberia", flag: "🇱🇷", code: "+231" },
  { value: "LY", label: "Libya", flag: "🇱🇾", code: "+218" },
  { value: "MG", label: "Madagascar", flag: "🇲🇬", code: "+261" },
  { value: "MW", label: "Malawi", flag: "🇲🇼", code: "+265" },
  { value: "ML", label: "Mali", flag: "🇲🇱", code: "+223" },
  { value: "MR", label: "Mauritania", flag: "🇲🇷", code: "+222" },
  { value: "MU", label: "Mauritius", flag: "🇲🇺", code: "+230" },
  { value: "MA", label: "Morocco", flag: "🇲🇦", code: "+212" },
  { value: "MZ", label: "Mozambique", flag: "🇲🇿", code: "+258" },
  { value: "NA", label: "Namibia", flag: "🇳🇦", code: "+264" },
  { value: "NE", label: "Niger", flag: "🇳🇪", code: "+227" },
  { value: "NG", label: "Nigeria", flag: "🇳🇬", code: "+234" },
  { value: "CG", label: "Republic of the Congo", flag: "🇨🇬", code: "+242" },
  { value: "RE", label: "Réunion", flag: "🇷🇪", code: "+262" },
  { value: "RW", label: "Rwanda", flag: "🇷🇼", code: "+250" },
  { value: "ST", label: "São Tomé and Príncipe", flag: "🇸🇹", code: "+239" },
  { value: "SN", label: "Senegal", flag: "🇸🇳", code: "+221" },
  { value: "SC", label: "Seychelles", flag: "🇸🇨", code: "+248" },
  { value: "SL", label: "Sierra Leone", flag: "🇸🇱", code: "+232" },
  { value: "SO", label: "Somalia", flag: "🇸🇴", code: "+252" },
  { value: "ZA", label: "South Africa", flag: "🇿🇦", code: "+27" },
  { value: "SS", label: "South Sudan", flag: "🇸🇸", code: "+211" },
  { value: "SD", label: "Sudan", flag: "🇸🇩", code: "+249" },
  { value: "TZ", label: "Tanzania", flag: "🇹🇿", code: "+255" },
  { value: "TG", label: "Togo", flag: "🇹🇬", code: "+228" },
  { value: "TN", label: "Tunisia", flag: "🇹🇳", code: "+216" },
  { value: "UG", label: "Uganda", flag: "🇺🇬", code: "+256" },
  { value: "ZM", label: "Zambia", flag: "🇿🇲", code: "+260" },
  { value: "ZW", label: "Zimbabwe", flag: "🇿🇼", code: "+263" },
  //Europe
  { value: "AL", label: "Albania", flag: "🇦🇱", code: "+355" },
  { value: "AD", label: "Andorra", flag: "🇦🇩", code: "+376" },
  { value: "AT", label: "Austria", flag: "🇦🇹", code: "+43" },
  { value: "BY", label: "Belarus", flag: "🇧🇾", code: "+375" },
  { value: "BE", label: "Belgium", flag: "🇧🇪", code: "+32" },
  { value: "BA", label: "Bosnia and Herzegovina", flag: "🇧🇦", code: "+387" },
  { value: "BG", label: "Bulgaria", flag: "🇧🇬", code: "+359" },
  { value: "HR", label: "Croatia", flag: "🇭🇷", code: "+385" },
  { value: "CY", label: "Cyprus", flag: "🇨🇾", code: "+357" },
  { value: "CZ", label: "Czech Republic", flag: "🇨🇿", code: "+420" },
  { value: "DK", label: "Denmark", flag: "🇩🇰", code: "+45" },
  { value: "EE", label: "Estonia", flag: "🇪🇪", code: "+372" },
  { value: "FO", label: "Faroe Islands", flag: "🇫🇴", code: "+298" },
  { value: "FI", label: "Finland", flag: "🇫🇮", code: "+358" },
  { value: "FR", label: "France", flag: "🇫🇷", code: "+33" },
  { value: "DE", label: "Germany", flag: "🇩🇪", code: "+49" },
  { value: "GI", label: "Gibraltar", flag: "🇬🇮", code: "+350" },
  { value: "GR", label: "Greece", flag: "🇬🇷", code: "+30" },
  { value: "HU", label: "Hungary", flag: "🇭🇺", code: "+36" },
  { value: "IS", label: "Iceland", flag: "🇮🇸", code: "+354" },
  { value: "IE", label: "Ireland", flag: "🇮🇪", code: "+353" },
  { value: "IT", label: "Italy", flag: "🇮🇹", code: "+39" },
  { value: "LV", label: "Latvia", flag: "🇱🇻", code: "+371" },
  { value: "LI", label: "Liechtenstein", flag: "🇱🇮", code: "+423" },
  { value: "LT", label: "Lithuania", flag: "🇱🇹", code: "+370" },
  { value: "LU", label: "Luxembourg", flag: "🇱🇺", code: "+352" },
  { value: "MT", label: "Malta", flag: "🇲🇹", code: "+356" },
  { value: "MD", label: "Moldova", flag: "🇲🇩", code: "+373" },
  { value: "MC", label: "Monaco", flag: "🇲🇨", code: "+377" },
  { value: "ME", label: "Montenegro", flag: "🇲🇪", code: "+382" },
  { value: "NL", label: "Netherlands", flag: "🇳🇱", code: "+31" },
  { value: "MK", label: "North Macedonia", flag: "🇲🇰", code: "+389" },
  { value: "NO", label: "Norway", flag: "🇳🇴", code: "+47" },
  { value: "PL", label: "Poland", flag: "🇵🇱", code: "+48" },
  { value: "PT", label: "Portugal", flag: "🇵🇹", code: "+351" },
  { value: "RO", label: "Romania", flag: "🇷🇴", code: "+40" },
  { value: "RU", label: "Russia", flag: "🇷🇺", code: "+7" },
  { value: "SM", label: "San Marino", flag: "🇸🇲", code: "+378" },
  { value: "RS", label: "Serbia", flag: "🇷🇸", code: "+381" },
  { value: "SK", label: "Slovakia", flag: "🇸🇰", code: "+421" },
  { value: "SI", label: "Slovenia", flag: "🇸🇮", code: "+386" },
  { value: "ES", label: "Spain", flag: "🇪🇸", code: "+34" },
  { value: "SE", label: "Sweden", flag: "🇸🇪", code: "+46" },
  { value: "CH", label: "Switzerland", flag: "🇨🇭", code: "+41" },
  { value: "UA", label: "Ukraine", flag: "🇺🇦", code: "+380" },
  { value: "GB", label: "United Kingdom", flag: "🇬🇧", code: "+44" },
  { value: "VA", label: "Vatican City", flag: "🇻🇦", code: "+379" },
  //South America.
  { value: "AR", label: "Argentina", flag: "🇦🇷", code: "+54" },
  { value: "BO", label: "Bolivia", flag: "🇧🇴", code: "+591" },
  { value: "BR", label: "Brazil", flag: "🇧🇷", code: "+55" },
  { value: "CL", label: "Chile", flag: "🇨🇱", code: "+56" },
  { value: "CO", label: "Colombia", flag: "🇨🇴", code: "+57" },
  { value: "EC", label: "Ecuador", flag: "🇪🇨", code: "+593" },
  { value: "FK", label: "Falkland Islands", flag: "🇫🇰", code: "+500" },
  { value: "GF", label: "French Guiana", flag: "🇬🇫", code: "+594" },
  { value: "GY", label: "Guyana", flag: "🇬🇾", code: "+592" },
  { value: "PY", label: "Paraguay", flag: "🇵🇾", code: "+595" },
  { value: "PE", label: "Peru", flag: "🇵🇪", code: "+51" },
  { value: "SR", label: "Suriname", flag: "🇸🇷", code: "+597" },
  { value: "UY", label: "Uruguay", flag: "🇺🇾", code: "+598" },
  { value: "VE", label: "Venezuela", flag: "🇻🇪", code: "+58" },
  // north America
  { value: "AI", label: "Anguilla", flag: "🇦🇮", code: "+1-264" },
  { value: "AG", label: "Antigua and Barbuda", flag: "🇦🇬", code: "+1-268" },
  { value: "BS", label: "Bahamas", flag: "🇧🇸", code: "+1-242" },
  { value: "BB", label: "Barbados", flag: "🇧🇧", code: "+1-246" },
  { value: "BZ", label: "Belize", flag: "🇧🇿", code: "+501" },
  { value: "BM", label: "Bermuda", flag: "🇧🇲", code: "+1-441" },
  { value: "CA", label: "Canada", flag: "🇨🇦", code: "+1" },
  { value: "KY", label: "Cayman Islands", flag: "🇰🇾", code: "+1-345" },
  { value: "CR", label: "Costa Rica", flag: "🇨🇷", code: "+506" },
  { value: "CU", label: "Cuba", flag: "🇨🇺", code: "+53" },
  { value: "DM", label: "Dominica", flag: "🇩🇲", code: "+1-767" },
  { value: "DO", label: "Dominican Republic", flag: "🇩🇴", code: "+1-809" },
  { value: "SV", label: "El Salvador", flag: "🇸🇻", code: "+503" },
  { value: "GD", label: "Grenada", flag: "🇬🇩", code: "+1-473" },
  { value: "GT", label: "Guatemala", flag: "🇬🇹", code: "+502" },
  { value: "HT", label: "Haiti", flag: "🇭🇹", code: "+509" },
  { value: "HN", label: "Honduras", flag: "🇭🇳", code: "+504" },
  { value: "JM", label: "Jamaica", flag: "🇯🇲", code: "+1-876" },
  { value: "MX", label: "Mexico", flag: "🇲🇽", code: "+52" },
  { value: "MS", label: "Montserrat", flag: "🇲🇸", code: "+1-664" },
  { value: "NI", label: "Nicaragua", flag: "🇳🇮", code: "+505" },
  { value: "PA", label: "Panama", flag: "🇵🇦", code: "+507" },
  { value: "PR", label: "Puerto Rico", flag: "🇵🇷", code: "+1-787" },
  { value: "KN", label: "Saint Kitts and Nevis", flag: "🇰🇳", code: "+1-869" },
  { value: "LC", label: "Saint Lucia", flag: "🇱🇨", code: "+1-758" },
  {
    value: "VC",
    label: "Saint Vincent and the Grenadines",
    flag: "🇻🇨",
    code: "+1-784",
  },
  { value: "TT", label: "Trinidad and Tobago", flag: "🇹🇹", code: "+1-868" },
  {
    value: "TC",
    label: "Turks and Caicos Islands",
    flag: "🇹🇨",
    code: "+1-649",
  },
  { value: "US", label: "United States", flag: "🇺🇸", code: "+1" },
  { value: "VG", label: "British Virgin Islands", flag: "🇻🇬", code: "+1-284" },
  { value: "VI", label: "U.S. Virgin Islands", flag: "🇻🇮", code: "+1-340" },

  //Asia

  { value: "AF", label: "Afghanistan", flag: "🇦🇫", code: "+93" },
  { value: "AM", label: "Armenia", flag: "🇦🇲", code: "+374" },
  { value: "AZ", label: "Azerbaijan", flag: "🇦🇿", code: "+994" },
  { value: "BH", label: "Bahrain", flag: "🇧🇭", code: "+973" },
  { value: "BD", label: "Bangladesh", flag: "🇧🇩", code: "+880" },
  { value: "BT", label: "Bhutan", flag: "🇧🇹", code: "+975" },
  { value: "BN", label: "Brunei", flag: "🇧🇳", code: "+673" },
  { value: "KH", label: "Cambodia", flag: "🇰🇭", code: "+855" },
  { value: "CN", label: "China", flag: "🇨🇳", code: "+86" },
  { value: "CY", label: "Cyprus", flag: "🇨🇾", code: "+357" },
  { value: "GE", label: "Georgia", flag: "🇬🇪", code: "+995" },
  { value: "IN", label: "India", flag: "🇮🇳", code: "+91" },
  { value: "ID", label: "Indonesia", flag: "🇮🇩", code: "+62" },
  { value: "IR", label: "Iran", flag: "🇮🇷", code: "+98" },
  { value: "IQ", label: "Iraq", flag: "🇮🇶", code: "+964" },
  { value: "IL", label: "Israel", flag: "🇮🇱", code: "+972" },
  { value: "JP", label: "Japan", flag: "🇯🇵", code: "+81" },
  { value: "JO", label: "Jordan", flag: "🇯🇴", code: "+962" },
  { value: "KZ", label: "Kazakhstan", flag: "🇰🇿", code: "+7" },
  { value: "KW", label: "Kuwait", flag: "🇰🇼", code: "+965" },
  { value: "KG", label: "Kyrgyzstan", flag: "🇰🇬", code: "+996" },
  { value: "LA", label: "Laos", flag: "🇱🇦", code: "+856" },
  { value: "LB", label: "Lebanon", flag: "🇱🇧", code: "+961" },
  { value: "MY", label: "Malaysia", flag: "🇲🇾", code: "+60" },
  { value: "MV", label: "Maldives", flag: "🇲🇻", code: "+960" },
  { value: "MN", label: "Mongolia", flag: "🇲🇳", code: "+976" },
  { value: "MM", label: "Myanmar", flag: "🇲🇲", code: "+95" },
  { value: "NP", label: "Nepal", flag: "🇳🇵", code: "+977" },
  { value: "KP", label: "North Korea", flag: "🇰🇵", code: "+850" },
  { value: "OM", label: "Oman", flag: "🇴🇲", code: "+968" },
  { value: "PK", label: "Pakistan", flag: "🇵🇰", code: "+92" },
  { value: "PS", label: "Palestine", flag: "🇵🇸", code: "+970" },
  { value: "PH", label: "Philippines", flag: "🇵🇭", code: "+63" },
  { value: "QA", label: "Qatar", flag: "🇶🇦", code: "+974" },
  { value: "SA", label: "Saudi Arabia", flag: "🇸🇦", code: "+966" },
  { value: "SG", label: "Singapore", flag: "🇸🇬", code: "+65" },
  { value: "KR", label: "South Korea", flag: "🇰🇷", code: "+82" },
  { value: "LK", label: "Sri Lanka", flag: "🇱🇰", code: "+94" },
  { value: "SY", label: "Syria", flag: "🇸🇾", code: "+963" },
  { value: "TW", label: "Taiwan", flag: "🇹🇼", code: "+886" },
  { value: "TJ", label: "Tajikistan", flag: "🇹🇯", code: "+992" },
  { value: "TH", label: "Thailand", flag: "🇹🇭", code: "+66" },
  { value: "TR", label: "Turkey", flag: "🇹🇷", code: "+90" },
  { value: "TM", label: "Turkmenistan", flag: "🇹🇲", code: "+993" },
  { value: "AE", label: "United Arab Emirates", flag: "🇦🇪", code: "+971" },
  { value: "UZ", label: "Uzbekistan", flag: "🇺🇿", code: "+998" },
  { value: "VN", label: "Vietnam", flag: "🇻🇳", code: "+84" },
  { value: "YE", label: "Yemen", flag: "🇾🇪", code: "+967" },
];

function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(null);

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);

    if (selectedOption) {
      setPhone(selectedOption.code);
    } else {
      setPhone("");
    }
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <span>{data.flag}</span>
      <span style={{ marginLeft: "8px" }}>{label}</span>
    </div>
  );

  // MySelect component to encapsulate the custom styling
  const MySelect = ({ options, ...props }) => (
    <Select
      {...props}
      options={options}
      components={{ Option: CustomOption }}
      // Add other props like onChange, value, etc.
    />
  );

  const handleSignup = async (e) => {
    setLoading(true);
    if (!selectedCountry) {
      setRegistrationStatus("Please select a country code");
      setLoading(false);

      return;
    }

    if (phone.length < 10 || phone.length > 13) {
      setRegistrationStatus("Enter a valid phone number");
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      setLoading(false);
      return;
    }

    e.preventDefault();
    try {
      await AuthService.signup(email, phone, password).then(
        (response) => {
          //check for token and user already exists with 200

          //const data = response.json();

          // console.log(data);

          if (response.status === "success") {
            setRegistrationStatus(
              "Registration successful!, please check your email."
            );

            setTimeout(() => {
              navigate("/signin"); // Redirect to the desired page after 5 seconds
            }, 5000);
          } else if (response.status === "unsuccessful") {
            setRegistrationStatus(response.message);
          } else {
            setRegistrationStatus("Registration failed. Please try again.");
          }
        },
        (error) => {
          //console.log(error);
          setRegistrationStatus("Registration failed. Please try again.");
        }
      );
    } catch (err) {
      //console.log(err);
      setRegistrationStatus("Registration failed. Please try again.");
    }
    setLoading(false);
  };

  return (
    <div className="w-screen h-screen  overflow-y-auto ">
      <img
        className="absolute h-screen w-screen object-cover"
        src={background}
      />
      <div className="bg-black/80 fixed  w-screen h-screen overflow-y-auto">
        <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
          <div className="md:mx-auto md:w-full md:max-w-md max-w-md">
            <Link to="/">
              <img
                className="mx-auto md:h-auto md:w-80 w-1/2"
                src={logo}
                alt="Kanema Online"
                // style={{ maxWidth: '100%', maxHeight: '80px' }}
              />
            </Link>
          </div>

          <div class="bg-white md:px-6 px-3 md:py-12 py-6 mt-10 sm:mx-auto sm:w-full sm:max-w-sm rounded-xl overflow-y-auto">
            <div className="flex  flex-col justify-center items-center ">
              <h1 className="text-black text-xl font-semibold mb-4">
                Registration
              </h1>
            </div>

            <form class="space-y-6 " onSubmit={handleSignup}>
              <div class="relative w-full">
                <div class="mt-4">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />{" "}
                    </svg>
                  </div>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    placeholder="Enter your Email address"
                    required
                    className="pl-10 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div class="relative w-full">
                <div class="mt-8">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-phone"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />{" "}
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />{" "}
                    </svg>
                  </div>

                  <div className="flex">
                    <div className="relative w-full  text-sm">
                      <MySelect
                        options={countries}
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        placeholder="Select country"
                      />

                      {selectedCountry && (
                        <span className="absolute inset-y-0 right-0 pr-2 flex items-center">
                          {selectedCountry.flag}
                        </span>
                      )}
                    </div>
                    <input
                      id="phonenumber"
                      name="phonenumber"
                      type="text"
                      autoComplete="number"
                      placeholder="881234567"
                      required
                      className="pl-10 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                      value={phone}
                      onChange={handlePhoneChange}
                    />
                  </div>
                </div>
              </div>

              <div class="relative w-full">
                <div class="mt-2">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-lock"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />{" "}
                    </svg>
                  </div>
                  <input
                    id="password1"
                    name="password"
                    type="password"
                    // type={visible ? "text" : "password"}
                    placeholder="Create Password"
                    required
                    className="pl-10 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div class="relative w-full">
                <div class="mt-2">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-lock"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z" />{" "}
                    </svg>
                  </div>
                  <input
                    id="password2"
                    name="password"
                    type={visible ? "text" : "password"}
                    placeholder="Confirm Password"
                    required
                    className="pl-10 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <div
                    onClick={() => setVisible(!visible)}
                    class="absolute inset-y-0 right-0 flex items-center pr-3"
                  >
                    {visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                  </div>
                </div>
              </div>

              {passwordMatchError && (
                <p className="text-red-500">
                  Password and confirm password do not match.
                </p>
              )}

              {registrationStatus && <p>{registrationStatus}</p>}

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                  disabled={loading}
                 
                >
                  {loading ? "Please wait..." : "Register"}
                </button>
              </div>
              <div class="text-sm text-center">
                <p className="text-sm text-center">
                  By creating an account you agree to our{" "}
                  <Link to="/terms-of-use" className="text-blue-500 hover:underline">
                    Terms of Use
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy-policy" className="text-blue-500 hover:underline">
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </form>
            <p class="mt-8 text-center text-sm text-black">
              Existing account? &nbsp;
              <Link to="/signin">
                <a class="font-semibold leading-6 text-red-600 hover:text-red-500">
                  Login
                </a>
              </Link>
            </p>
          </div>
          <div class="hidden md:block absolute mt-8 inset-y-0 right-0 text-center  sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <a
              href="https://www.facebook.com/kanemaonline1"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/kanemaonline"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>

            <a
              href="https://www.twitter.com/KanemaOnline"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
            <p className="mt-2 md:text-black text-white">
              &copy; Static Computers Inc. All rights reserved.
            </p>
          </div>
          <div class="md:hidden block md:absolute mt-4 md:inset-y-0 md:right-0 text-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <a
              href="https://www.facebook.com/kanemaonline1"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>

            <a
              href="https://www.instagram.com/kanemaonline"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>

            <a
              href="https://www.twitter.com/KanemaOnline"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
            <p className="mt-6 text-white">
              &copy; Static Computers Inc. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
