import React from 'react'
import UserForm from './UserForm'
import TopBar from './TopBar'
import Navbar from './Navbar'
import SideBar from './SideBar'
import User from './User'
import Footer from './Footer'

const EditProfile = () => {
  return (
    <>
    <User/>
    <SideBar/>
    </>
  )
}

export default EditProfile