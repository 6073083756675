import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import TopBar from '../components/TopBar';
import ProfileNav from '../components/ProfileNav';
import PendingSubcription from '../components/PendingSubcription';
import avatar from '../assets/images/e.png'
import SubscribedEvents from '../components/SubscribedEvents';

const Profile = () => {

//   import React, { useState, useEffect } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import AuthService from "../services/auth.service";

  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin"); // Redirect to the signup page if user is not logged in
    }
  }, [currentUser, navigate]);
  
    return (
      
      
      <div class="">
        <div class="card hover:shadow-none relative flex flex-col shadow-lg">
          <TopBar/>
          <div class="px-96 ml-40 profile flex m-3 ml-4 text-white absolute top-0">
            <img class="h-60 p-2 bg-black border border-black rounded-full" src={avatar} alt=""/>
            <div class="title mt-28 py-6 ml-3 font-bold flex flex-col">
              <h1>Bill Van Demister</h1>
              <h3>Vandemister0@gmail.com</h3>
            </div>
          </div>
          <div className='mt-80'></div>
          <ProfileNav/>
          {/* <PendingSubcription/> */}
          <SubscribedEvents/>
        </div>
      </div>
  )
}

export default Profile