import React, { useState, useEffect } from "react";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import { Link } from "react-router-dom";
import authHeader from '../services/auth-header'; // Replace with thconst headers = authHeader();e actual path to the file containing the exported function
import viewCountService from "../services/viewCountService"; // Import the service
import Banner from "../components/Banner"


function LiveTVJumbotron() {
  const fectTrendingUrl = KanemaRequests.fetTvs;
  const [trendingVideos, setTrendingVideos] = useState([]);
  const category = "tvs";

useEffect(() => {
  async function fetchTrendingData() {
    const headers = authHeader();
    const response = await axios.get(fectTrendingUrl,{headers});
    const videos = response.data;

    // Filter the videos based on the specified conditions
    let filteredVideos = videos.filter(video => {
      return video.status.lifetime > 0 && video.status.type === "normal";
    });

    // Check if there are any "live" videos
    const liveVideos = videos.filter(video => video.status.type === "live");
    if (liveVideos.length > 0) {
      filteredVideos = liveVideos; // Override with live videos
    }

    // Sort the filtered videos based on views in descending order
    filteredVideos.sort((a, b) => b.views.length - a.views.length);

    if (filteredVideos.length > 0) {
      const firstVideo = filteredVideos[0];
      setTrendingVideos([firstVideo]);
    }
  }

  fetchTrendingData();
}, [fectTrendingUrl]);
  //console.log(trendingVideos.cover_url)
  //console.log(trendingVideos[0]);

     // Function to handle link click and update view count
  const handleLinkClick = async (id) => {
    try {
      // Call the updateViewCount function from the service
      await viewCountService.updateViewCount(category,id);
    
    } catch (error) {
      // Handle errors if needed
      console.error("Error updating view count:", error);
    }
  };

  return (
    <Link
       onClick={() => handleLinkClick(trendingVideos[0] ? trendingVideos[0]._id:"")} 
      to={`/currentVideo?name=${trendingVideos[0] ? trendingVideos[0].name : ""}&id=${trendingVideos[0] ? trendingVideos[0]._id:""}&url=${trendingVideos[0]
            ? `${trendingVideos[0].stream_key}`
            : ""}&category=${category}`}
    >

          
      <Banner movie={trendingVideos[0]}  />


    </Link>

  );
}

export default LiveTVJumbotron;
