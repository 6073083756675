import { getAccessToken, handleTokenExpiration} from '../services/auth.jwt.js'; // Replace with the actual path to the file containing the exported function

export default function authHeader() {
    // localStorage.setItem("accessToken", accessToken);
  const accessToken = getAccessToken();
  const reaccessToken = handleTokenExpiration();
  //console.log("the handleTokenExpiration header is ======", reaccessToken)
  //console.log("the authentication header is ======",accessToken);
  
  if (accessToken) {
     return {  Authorization: `Bearer ${accessToken}`};
    //return { "x-auth-token": user.accessToken Authorization: `Bearer ${accessToken}`, };
  } else {
    return {};
  }
}

