import Navbar from "../components/Navbar";
import React, { useState, useEffect } from "react";
import Play from "../assets/images/play.png";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import { useNavigate, Link } from "react-router-dom";
import authHeader from "../services/auth-header"; 
import Footer from "../components/Footer";
import BottomNav from "../components/BottomNav";
import AuthService from "../services/auth.service";
import LiveEventsJumbotron from "../components/LiveEventsJumbotron";
import "./LiveEventGrid.css";
import viewCountService from "../services/viewCountService"; 

const LiveEvents = () => {

  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin"); 
    }
  }, [currentUser, navigate]);

  const fetchLiveEventsUrl = KanemaRequests.fetEvents;
  const [liveEventVideos, setLiveEventsVideos] = useState([]);
  const category = "events";

  const slideToLeft = () => {
    var slider = document.getElementById("LiveEventSlider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideToRight = () => {
    var slider = document.getElementById("LiveEventSlider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  useEffect(() => {
    async function fetchTrendingData() {
      const headers = authHeader();

      const response = await axios.get(fetchLiveEventsUrl, { headers });
      const videos = response.data;

      // Filter the videos based on the specified conditions
      const filteredVideos = videos.filter((video) => {
        return video.status.lifetime > 0 && video.status.type === "normal";
      });

      // const liveVideos = videos.filter((video) => video.status.type === "live");
      // if (liveVideos.length > 0) {
      //   filteredVideos = liveVideos; // Override with live videos
      // }

      // Sort the filtered videos based on views in descending order
      let data = response.data;

      // Filter videos with visibility true and lifetime more than zero
      data = data.filter(
        (video) => video.status.visibility && video.status.lifetime > 0
      );

      // Sort the data based on the number of views (highest to lowest)
      data.sort((a, b) => b.views.length - a.views.length);

      setLiveEventsVideos(data);
    }

    fetchTrendingData();
  }, [fetchLiveEventsUrl]);

  const handleLinkClick = async (id) => {
    try {
      // Call the updateViewCount function from the service
      await viewCountService.updateViewCount(category, id);
    } catch (error) {
      // Handle errors if needed
      console.error("Error updating view count:", error);
    }
  };

  return (
    <div>
      <Navbar />

      <div className="md:hidden">
        <BottomNav />
      </div>

      <LiveEventsJumbotron style={{ marginBottom: "1rem" }} />

      <div class="md:my-10 my-8">
        <div class="flex md:justify-between justify-between">
          <a
            href="#"
            class="text-white hover:text-gray-600 md:text-2xl font-urbanist"
          >
            Previous Live Events
          </a>
        </div>
        <hr class="md:w-40 w-28 h-1 my-2 bg-red-600 border-0 md:my-0 dark:bg-red-600" />
      </div>

      <div className="container mx-auto md:mb-2">
        {/* Adjusted margin-bottom class to md:mb-2 */}
<div className="grid-container h-screen overflow-y-scroll scrollbar-thin scrollbar-thumb-transparent">
          <div className="">
            <div className="grid-wrapper">
              <div className="grid md:grid-cols-6 md:gap-1 gap-2 grid-cols-2 ">
                {liveEventVideos.map((le) => (
                  <Link
                    onClick={() => handleLinkClick(le._id)}
                    to={`/currentVideo?name=${le.name}&id=${le._id}&category=${category}&url=${le.video_url}`}
                    key={le._id}
                  >
                    <div className="col-span-12 rounded-lg sm:col-span-6 m-2">
                      <img
                        alt="Kanema"
                        src={le.thumb_nail}
                        className="object-cover rounded-l h-52 w-full"
                      />
                      <div className="">
                        <p className="mt-2 text-white md:text-sm text-xs">
                          {le.name.length > 20
                            ? `${le.name.substring(0, 20)}...`
                            : le.name}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="mb-2"></div>
      </div>

      <Footer />
    </div>
  );
};

export default LiveEvents;


//  <div class="inline-block px-3" style={{ height: "200px" }}>
//   <div className="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//     <div className="bg-zinc-950 md:w-60 w-40 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl ">
//       <div className="bg-center bg-no-repeat">
//         <img
//           src={le.thumb_nail}
//           className="md:w-70 md:h-80"
//           style={{
//             // position: "absolute",
//             width: "202px",
//             height: "190px",
//             left: "27px",
//             top: "1232px",
//             borderRadius: "4px",
//           }}
//         />
//       </div>
//       <div className="flex justify-between items-center">
//         <div className="">
//           <p className="mt-2 text-neutral-600 md:text-sm text-xs">
//             {le.name}
//           </p>
//         </div>
//         <div className="md:w-16 w-8">
//           <img
//             className="md:px-24 px-24 md:py-4 py-8 "
//             src={Play}
//             style={{ opacity: 0.5 }}
//           />
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
