import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import AllChannels from "../components/AllChannels";
import Navbar from "../components/Navbar";
import WatchHistory from "../components/WatchHistory";
import Footer from "../components/Footer";
import BottomNav from "../components/BottomNav";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import Play from "../assets/images/play.png";
import authHeader from "../services/auth-header"; // Replace with thconst headers = authHeader();e actual path to the file containing the exported function
import viewCountService from "../services/viewCountService"; // Import the service
import WYW from "../components/WYW";
import RecommendedVideos from "../components/RecommendedVideos";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import VodJumbotron from "../components/VodJumboTron";
import Swal from "sweetalert2";

const MoreTrending = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin"); // Redirect to the signin page if the user is not logged in
    }
  }, [currentUser, navigate]);

  const fetchTrendingUrl = KanemaRequests.fetTrends;
  const [trendingVideos, setTrendingVideos] = useState([]);
  const category = "trends";

  const slideToLeft = () => {
    var slider = document.getElementById("VODslider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideToRight = () => {
    var slider = document.getElementById("VODslider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  useEffect(() => {
    async function fetchTrendingData() {
      const headers = authHeader();
      const response = await axios.get(fetchTrendingUrl, { headers });
      const videos = response.data;

      const filteredVideos = videos.filter(
        (video) => video.status.lifetime > 0 && video.status.type === "normal"
      );

      filteredVideos.sort((a, b) => b.views - a.views);

      setTrendingVideos(filteredVideos);
    }

    fetchTrendingData();
  }, [fetchTrendingUrl]);

  // Function to handle link click and update view count
  const handleLinkClick = async (id, isPublished) => {
    try {
      if (!isPublished) {
        // Show SweetAlert if the video is not published
        await Swal.fire({
          icon: "warning",
          title: "Coming soon!",
          text: "The video you are trying to play is not yet published.",
          customClass: {
            container: "bg-black text-white",
            title: "text-black",
            content: "text-black",
            confirmButton: "bg-black text-white hover:bg-gray-700",
          },
          confirmButtonColor: "#000000",
          width: 400,
          height: 20,
          animation: false,
        });
        return;
      }

      // Call the updateViewCount function from the service
      await viewCountService.updateViewCount(category, id);
    } catch (error) {
      // Handle errors if needed
      console.error("Error updating view count:", error);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="md:hidden">
        <BottomNav />
      </div>

      <div className="flex md:justify-between justify-between">
        <a className="text-white hover:text-gray-600 md:text-2xl font-urbanist">
          More Trending
        </a>
      </div>
      <hr className="md:w-48 w-24 h-1 my-0 bg-red-600 border-0 md:my-0 dark:bg-red-600" />

      <div className="container mx-auto overflow-x-auto">
        <div className="grid md:grid-cols-4 md:gap-6 grid-cols-2">
          {trendingVideos.map((trendingVideo) => (
            <div
              className="inline-block px-3 md:w-auto w-1/2"
              key={trendingVideo._id}
            >
              <div>
                {/* Check if the video is published */}
                {trendingVideo.status.publish ? (
                  <Link
                    onClick={() =>
                      handleLinkClick(
                        trendingVideo._id,
                        trendingVideo.status.publish
                      )
                    }
                    to={`/currentVideo?name=${trendingVideo.name}&id=${trendingVideo._id}&url=${trendingVideo.video_url}&category=${category}`}
                  >
                    <div className="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
                      <div className="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
                        <div
                          className="bg-zinc-950 md:w-80 w-50 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300"
                          style={{
                            backgroundImage: `url(${trendingVideo.thumb_nail})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          <a>
                            <img
                              className="md:px-24 px-20 md:py-4 py-8 "
                              src={Play}
                              alt="Play Icon"
                            />
                          </a>
                        </div>
                        <div className="flex justify-between items-left">
                          <div className="items-left">
                            <h1 className="md:font-semibold md:text-sm text-xs text-white">
                              {trendingVideo.name}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  // If not published, show a disabled link
                  <div
                    onClick={() =>
                      handleLinkClick(
                        trendingVideo._id,
                        trendingVideo.status.publish
                      )
                    }
                    className="cursor-not-allowed"
                  >
                    <div className="bg-zinc-950 text-gray-500 text-lg font-bold text-center rounded">
                      <div className="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 ">
                        <div
                          className="bg-zinc-950 md:w-80 w-50 md:h-54 overflow-hidden md:p-4 p-2 "
                          style={{
                            backgroundImage: `url(${trendingVideo.thumb_nail})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          <a>
                            <img
                              className="md:px-24 px-20 md:py-4 py-8 "
                              src={Play}
                              alt="Play Icon"
                            />
                          </a>
                        </div>
                        <div className="flex justify-between items-left">
                          <div className="items-left">
                            <h1 className="md:font-semibold md:text-sm text-xs text-gray-500">
                              {trendingVideo.name}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MoreTrending;
