import React from 'react';
import { Link } from "react-router-dom";
import viewCountService from "../services/viewCountService"; // Import the service

const VideoContent = ({ videos, vodeoCategory }) => {

  const handleLinkClick = async (id) => {
      try {
        // Call the updateViewCount function from the service
        await viewCountService.updateViewCount(vodeoCategory, id);

      } catch (error) {
        // Handle errors if needed
        console.error("Error updating view count:", error);
      }
    };


  const generateContent = (videos) => {
    
    return videos.map((video) => (
      <div key={video.id}>
        <div className="md:hidden block">
          <Link
            onClick={() => handleLinkClick(video._id)}
            to={`/currentVideo?name=${video.name}&id=${video._id}&category=${vodeoCategory}&url=${video.stream_key}`}
          >
            <div className="flex items-center max-w-screen-sm p-2 shadow-md rounded-sm overflow-hidden">
              <div className="w-1/4 md:w-20 md:aspect-w-16 md:aspect-h-9">
                <img
                  src={video.thumb_nail}
                  alt={video.name}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="bg-black">
                <span className="text-white text-xs uppercase px-2">
                  {video.name}
                </span>
              </div>
            </div>
          </Link>
        </div>
        <div className="hidden md:block">
          <Link
              onClick={() => handleLinkClick(video._id)}
            to={`/currentVideo?name=${video.name}&id=${video._id}&category=${vodeoCategory}&url=${video.stream_key}`}
          >
            <div className="max-w-screen-sm shadow-md rounded-sm overflow-hidden">
              <div className="aspect-w-16 aspect-h-9">
                <img
                  src={video.thumb_nail}
                  alt={video.name}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="bg-black">
                <span className="text-white text-xs uppercase px-2">
                  {video.name}
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ));
  };

  let content;

  if (vodeoCategory === 'trends') {
    content = generateContent(videos);
  } else if (vodeoCategory === 'tvs') {
    content = generateContent(videos);
  } else if (vodeoCategory === 'events') {
    content = generateContent(videos);
  } else if (vodeoCategory === 'vod') {
    content = generateContent(videos);
  }

  return <>{content}</>;
};

export default VideoContent;
