import React from 'react'
import PendingSubcription from './PendingSubcription'
import SubscribedEvents from './SubscribedEvents'
import PaymentsMethod from './PaymentsMethod'

const ProfileNav = () => {
  return (
    <div>
        
        <nav class="relative px-4 py-4 flex justify-between items-center">
            
            <ul class="text-gray-500 text-sm absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-32">
                <li><a class="hover:text-gray-300" href="#">Pending Subscription</a></li>
                
                <li><a class="text-white font-bold" href="#">Subscribed Events</a></li>
                
                <li><a class="hover:text-gray-300" href="#">Payment Method</a></li>
                
                <li><a class="hover:text-gray-300" href="#">Edit Profile</a></li>
            </ul>
            
        </nav>
        
    </div>
  )
}

export default ProfileNav