import Navbar from "./Navbar";
import BottomNav from "./BottomNav";
import { useLocation } from "react-router-dom";
import "video.js/dist/video-js.css";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import ReactPlayer from "react-player";
import shareLogo from "../assets/images/icons8-share-48 (1).png";
import favoriteLogo from "../assets/images/icons8-favorite-50.png";
import liveLogo from "../assets/images/live.png";
import RecommendedVideo from "./RecommendedVideo";
import authHeader from "../services/auth-header";
import VideoContent from "../components/VideoContent";
import Flowplayer from "@flowplayer/react-flowplayer"
import OVPPlugin from "@flowplayer/player/plugins/ovp"
import HLSPlugin from "@flowplayer/player/plugins/hls"
import flowplayer from "@flowplayer/player"
import Feedback  from '../assets/images/message-square- alert.png'

function PlayedVideo() {
  flowplayer(HLSPlugin, OVPPlugin);
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  useEffect(() => {
    if (!currentUser) {
      navigate("/signin"); // Redirect to the signup page if user is not logged in
    }
  }, [currentUser, navigate]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const videoName = queryParams.get("name");

  const [isMobile, setIsMobile] = useState(false);
  const vodeoCategory = queryParams.get("category");

  const fectTrendingUrl = KanemaRequests.fetTrends;
  const fectEventUrl = KanemaRequests.fetEvents;
  const fectVodUrl = KanemaRequests.fetVod;
  const fectTVUrl = KanemaRequests.fetTvs;

  const accessToken = localStorage.getItem("accessToken");

  const headers = authHeader();

  const [trends, setTrends] = useState([]);
  const [vods, setVods] = useState([]);
  const [events, setEvents] = useState([]);
  const [tvs, setTvs] = useState([]);

  const videoUrl = queryParams.get("url");
  const videoID = queryParams.get("id");

  console.log("The video category...",vodeoCategory);
  console.log("The video videoID...",videoID)

  const getPlayerConfig = (url) => {
    return {
      file: {
        attributes: {
          crossOrigin: "https://kanemaonline.com",
        },
        httpHeaders: {
          "Access-Control-Allow-Origin": "*", // Add this header to allow cross-origin requests
          Authorization: `Bearer ${accessToken}`,
        },
        forceHLS: true,
        hlsOptions: {
          xhrSetup: (xhr) => {
            xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
          },
        },
        sources: [
          {
            src: url,
            type: "application/x-mpegURL",
          },
        ],
      },
    };
  };

  // useEffect(() => {
  //   updateViewCount();
  // }, []);

  // const updateViewCount = async () => {
  //   const endpoint = `https://kanemaonline.com/${vodeoCategory}/${videoID}/views`;
 

  //   try {
  //     const resuilt = await axios.put(endpoint, {
  //       // Add any additional data you need to send in the PUT request
  //     });
  //     console.log("updating view count:",resuilt);

  //     //setViewCount(prevCount => prevCount + 1);
  //   } catch (error) {
  //     console.error("Error updating view count:", error);
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(fectTrendingUrl, { headers });
      const videos = response.data;
      const filteredVideos = videos.filter((video) => {
        return video.status.lifetime > 0 && video.status.type === "normal";
      });
      filteredVideos.sort((a, b) => b.views - a.views);
      setTrends(filteredVideos);

      const vodResponse = await axios.get(fectVodUrl, { headers });
      const vod = vodResponse.data;
      const filteredvod = vod.filter((video) => {
        return video.status.lifetime > 0 && video.status.type === "normal";
      });
      filteredvod.sort((a, b) => b.views - a.views);
      setVods(filteredvod);

      const eventsResponse = await axios.get(fectEventUrl, { headers });
      const videosevennt = eventsResponse.data;
      const filteredvideosevennt = videosevennt.filter((video) => {
        return video.status.lifetime > 0 && video.status.type === "normal";
      });
      filteredvideosevennt.sort((a, b) => b.views - a.views);
      setEvents(filteredvideosevennt);

      const tvresponse = await axios.get(fectTVUrl, { headers });
      const liveTvData = tvresponse.data.filter((liveTv) => {
        return liveTv.status.lifetime > 0 && liveTv.status.type === "normal";
      });
      liveTvData.sort((a, b) => b.views - a.views);
      setTvs(liveTvData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  let content = null;

  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (vodeoCategory === "trends") {
    content = <VideoContent videos={trends} vodeoCategory={vodeoCategory} />;
  } else if (vodeoCategory === "tvs") {
    content = <VideoContent videos={tvs} vodeoCategory={vodeoCategory} />;
  } else if (vodeoCategory === "events") {
    content = <VideoContent videos={events} vodeoCategory={vodeoCategory} />;
  } else if (vodeoCategory === "vods") {
    content = <VideoContent videos={vods} vodeoCategory={vodeoCategory} />;
  }

  return (
    <>
      <Navbar />
      <div className="md:hidden">
        <BottomNav />
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-3/4">
          <div className="w-screen-md  shadow-md rounded-lg overflow-hidden relative sticky top-20">
            <div className="aspect-w-16 aspect-h-9">
              <div>
                <ReactPlayer
                  url={videoUrl}
                  controls={true}
                  width="100%"
                  height="150%"
                  playing
                  config={getPlayerConfig(videoUrl)}
                />
              </div>
            </div>
            <div className="bg-black">
              <span className="text-white text-xs uppercase px-2">
                {videoName}
              </span>
            </div>
            <div className="flex justify-between p-1">
              <div className="flex text-white">
                <button>
                  <img src={favoriteLogo} className="h-6" alt="" />
                </button>
              </div>
              <div className="flex text-white ml-4">
                <button>
                  <img src={shareLogo} className="h-6" alt="" />
                </button>
              </div>
                <div className="flex text-white ml-4">
                <button>
               <img src={Feedback} className="h-6" alt="" />
                </button>
              </div>
              
              <div className="flex text-white ml-auto">
                {vodeoCategory === "tvs" ? (
                  <button onClick={() => window.location.reload()}>
                    <img src={liveLogo} className="h-16" alt="" />
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {isMobileView ? (
          <div className="mt-8">
            <span style={{ padding: "4px", color: "white", fontSize: "14px" }}>
              {vodeoCategory === "tvs" && "All Live TVs"}
              {vodeoCategory === "events" && "All Events"}
              {vodeoCategory === "vods" && "All Videos"}
              {vodeoCategory === "trends" && "All Trending"}
            </span>
            <RecommendedVideo vodeoCategory={vodeoCategory} />
          </div>
        ) : (
          <div className="md:w-1/6 mx-auto mt-4 mb-2 recommended-videos">
            <div className="flex flex-col space-y-2">{content}</div>
          </div>
        )}
      </div>
    </>
  );
}

export default PlayedVideo;

// function PlayedVideo() {

//   const navigate = useNavigate();
//   const currentUser = AuthService.getCurrentUser();
//   useEffect(() => {
//     if (!currentUser) {
//       navigate("/signin"); // Redirect to the signup page if user is not logged in
//     }
//   }, [currentUser, navigate]);

//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const videoName = queryParams.get("name");

//   const [isMobile, setIsMobile] = useState(false);
//   const vodeoCategory = queryParams.get("category");

//   const fectTrendingUrl = KanemaRequests.fetTrends;
//   const fectEventUrl = KanemaRequests.fetEvents;
//   const fectVodUrl = KanemaRequests.fetVod;
//   const fectTVUrl = KanemaRequests.fetTvs;

//   const accessToken = localStorage.getItem("accessToken");

//   const headers = authHeader();

//   const [trends, setTrends] = useState([]);
//   const [vods, setVods] = useState([]);
//   //const [videoBlobUrl, setVideoBlobUrl] = useState('');
//   const [events, setEvents] = useState([]);
//   const [tvs, setTvs] = useState([]);

//   const videoUrl = queryParams.get("url");
//   const videoID = queryParams.get("id");

// const getPlayerConfig = (url) => {
//   return {
//     file: {
//       attributes: {
//         crossOrigin: 'anonymous',
//       },
//       httpHeaders: {
//         'Access-Control-Allow-Origin': '*', // Add this header to allow cross-origin requests
//         Authorization: `Bearer ${accessToken}`,
//       },
//       forceHLS: true,
//       hlsOptions: {
//         xhrSetup: (xhr) => {
//           xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
//         },
//       },
//       sources: [
//         {
//           src: url,
//           type: 'application/x-mpegURL',
//         },
//       ],
//     },
//   };
// };

//   useEffect(() => {
//     updateViewCount();
//   }, []);

//   const updateViewCount = async () => {
//     const endpoint = `https://kanemaonline.com/${vodeoCategory}/${videoID}/views`;

//     try {
//       await axios.put(endpoint, {
//         // Add any additional data you need to send in the PUT request
//       });

//       //setViewCount(prevCount => prevCount + 1);
//     } catch (error) {
//       console.error("Error updating view count:", error);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(fectTrendingUrl, { headers });
//       const videos = response.data;
//       // Filter the videos based on the specified conditions
//       const filteredVideos = videos.filter((video) => {
//         return video.status.lifetime > 0 && video.status.type === "normal";
//       });
//       // Sort the filtered videos based on views in descending order
//       filteredVideos.sort((a, b) => b.views - a.views);
//       setTrends(filteredVideos);

//       // set vods
//       const vodResponse = await axios.get(fectVodUrl, { headers });
//       const vod = vodResponse.data;
//       // Filter the videos based on the specified conditions
//       const filteredvod = vod.filter((video) => {
//         return video.status.lifetime > 0 && video.status.type === "normal";
//       });
//       // Sort the filtered videos based on views in descending order
//       filteredvod.sort((a, b) => b.views - a.views);
//       setVods(filteredvod);

//       const eventsResponse = await axios.get(fectEventUrl, { headers });
//       const videosevennt = eventsResponse.data;

//       // Filter the videos based on the specified conditions
//       const filteredvideosevennt = videosevennt.filter((video) => {
//         return video.status.lifetime > 0 && video.status.type === "normal";
//       });

//       // Sort the filtered videos based on views in descending order
//       filteredVideos.sort((a, b) => b.views - a.views);
//       setEvents(filteredvideosevennt);

//       //setTvs
//       const tvresponse = await axios.get(fectTVUrl, { headers });
//       const liveTvData = tvresponse.data.filter((liveTv) => {
//         return liveTv.status.lifetime > 0 && liveTv.status.type === "normal";
//       });
//       // Sort the liveTvData based on views in descending order
//       liveTvData.sort((a, b) => b.views - a.views);
//       setTvs(liveTvData);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   //switching content from mobile to desktop
//   let content = null;

//   const [isMobileView, setIsMobileView] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobileView(window.innerWidth <= 768); // Adjust the breakpoint as needed
//     };

//     handleResize(); // Call the function initially to set the initial view
//     window.addEventListener("resize", handleResize);

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   if (vodeoCategory === "trends") {
//     content = trends.map((trend) => (
//       <div>
//         <div className="md:hidden block">
//           <Link
//             to={`/currentVideo?name=${trend.name}&id=${trend._id}&category=${vodeoCategory}&url=${trend.stream_key}`}
//           >
//             <div
//               key={trend.id}
//               className="flex items-center max-w-screen-sm p-2  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className=" w-1/4 md:w-20 md:aspect-w-16 md:aspect-h-9">
//                 <img
//                   src={trend.thumb_nail}
//                   alt={trend.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {trend.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="hidden md:block">
//           <Link
//             to={`/currentVideo?name=${trend.name}&id=${trend._id}&category=${vodeoCategory}&url=${trend.stream_key}`}
//           >
//             <div
//               key={trend.id}
//               className="max-w-screen-sm  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className="aspect-w-16 aspect-h-9">
//                 <img
//                   src={trend.thumb_nail}
//                   alt={trend.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {trend.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//       </div>
//     ));
//   } else if (vodeoCategory === "tvs") {
//     content = tvs.map((tv) => (
//       <div>
//         <div className="md:hidden block">
//           <Link
//             to={`/currentVideo?name=${tv.name}&id=${tv._id}&category=${vodeoCategory}&url=${tv.stream_key}`}
//           >
//             <div
//               key={tv.id}
//               className="flex items-center max-w-screen-sm p-2  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className=" w-1/4 md:w-20 md:aspect-w-16 md:aspect-h-9">
//                 <img
//                   src={tv.thumb_nail}
//                   alt={tv.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {tv.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="hidden md:block">
//           <Link
//             to={`/currentVideo?name=${tv.name}&id=${tv._id}&category=${vodeoCategory}&url=${tv.stream_key}`}
//           >
//             <div
//               key={tv.id}
//               className="max-w-screen-sm  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className="aspect-w-16 aspect-h-9">
//                 <img
//                   src={tv.thumb_nail}
//                   alt={tv.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {tv.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//       </div>
//     ));
//   } else if (vodeoCategory === "events") {
//     content = events.map((event) => (
//       <div>
//         <div className="md:hidden block">
//           <Link
//             to={`/currentVideo?name=${event.name}&id=${event._id}&category=${vodeoCategory}&url=${event.stream_key}`}
//           >
//             <div
//               key={event._id}
//               className="flex items-center max-w-screen-sm p-2  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className=" w-1/4 md:w-20 md:aspect-w-16 md:aspect-h-9">
//                 <img
//                   src={event.thumb_nail}
//                   alt={event.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {event.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="hidden md:block">
//           <Link
//             to={`/currentVideo?name=${event.name}&id=${event._id}&category=${vodeoCategory}&url=${event.stream_key}`}
//           >
//             <div
//               key={event._id}
//               className="max-w-screen-sm  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className="aspect-w-16 aspect-h-9">
//                 <img
//                   src={event.thumb_nail}
//                   alt={event.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {event.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//       </div>
//     ));
//   } else if (vodeoCategory === "vod") {
//     content = vods.map((vod) => (
//       <div>
//         <div className="md:hidden block">
//           <Link
//             to={`/currentVideo?name=${vod.name}&url=${vod.stream_key}&category=${vodeoCategory}`}
//           >
//             <div
//               key={vod.id}
//               className="flex items-center max-w-screen-sm p-2  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className=" w-1/4 md:w-20 md:aspect-w-16 md:aspect-h-9">
//                 <img
//                   src={vod.thumb_nail}
//                   alt={vod.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {vod.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//         <div className="hidden md:block">
//           <Link
//             to={`/currentVideo?name=${vod.name}&url=${vod.stream_key}&category=${vodeoCategory}`}
//           >
//             <div
//               key={vod.id}
//               className="max-w-screen-sm  shadow-md rounded-lg overflow-hidden"
//             >
//               <div className="aspect-w-16 aspect-h-9">
//                 <img
//                   src={vod.thumb_nail}
//                   alt={vod.name}
//                   className="object-cover w-full h-full"
//                 />
//               </div>
//               <div className="bg-black">
//                 <span className="text-white text-xs uppercase px-2">
//                   {vod.name}
//                 </span>
//               </div>
//             </div>
//           </Link>
//         </div>
//       </div>
//     ));
//   }

//   return (
//     <>
//       <Navbar />
//       <div className="md:hidden">
//         <BottomNav />
//       </div>
//       <div className="flex flex-col md:flex-row">
//         <div className="md:w-3/4">
//           <div className="w-screen-md  shadow-md rounded-lg overflow-hidden relative sticky top-20">
//             <div className="aspect-w-16 aspect-h-9">
//               <div>

//              <ReactPlayer
//               url={videoUrl}
//               controls={true}
//               width="100%"
//               height="150%"
//               playing
//              config={getPlayerConfig(videoUrl)}
//             />

//             </div>
//             </div>
//             <div className="bg-black">
//               <span className="text-white text-xs uppercase px-2">
//                 {videoName}
//               </span>
//             </div>
//             <div className="flex justify-between p-1">
//               <div className="flex text-white">
//                 <button>
//                   <img src={favoriteLogo} className="h-6" alt="" />
//                 </button>
//               </div>
//               <div className="flex text-white ml-4">
//                 <button>
//                   <img src={shareLogo} className="h-6" alt="" />
//                 </button>
//               </div>
//               <div className="flex text-white ml-auto">
//                 {vodeoCategory === "tvs" ? (
//                   <button onClick={() => window.location.reload()}>
//                     <img src={liveLogo} className="h-6" alt="" />
//                   </button>
//                 ) : (
//                   ""
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//         {isMobileView ? (

//           <div className="mt-8">
//             <span style={{ padding: "4px", color: "white", fontSize: "14px" }}>
//               {vodeoCategory === "tvs" && "More Live TVs"}
//               {vodeoCategory === "events" && "More Events"}
//               {vodeoCategory === "vod" && "More Videos"}
//               {vodeoCategory === "trends" && "More Trending"}
//           </span>

//             <RecommendedVideo vodeoCategory={vodeoCategory} />
//           </div>
//         ) : (
//           <div className="md:w-1/6 mx-auto mt-4 mb-2 recommended-videos">
//             <div className="flex flex-col space-y-2">{content}</div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// }

// export default PlayedVideo;

// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import ReactPlayer from 'react-player';
// import Navbar from './Navbar';
// import BottomNav from './BottomNav';
// import RecommendedVideo from './RecommendedVideo';

// function PlayedVideo() {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const videoName = queryParams.get("name");
//   const videoUrl = queryParams.get("url");

//   console.log(videoUrl);

//   return (
//     <>
//       <Navbar />
//       <div className="md:hidden">
//         <BottomNav />
//       </div>

//       <div className="bg-black my-4 flex flex-col md:flex-row md:space-x-4">
//         <div className="md:w-2/3 flex justify-center">
//           <div className="md:max-w-screen-md border shadow-md rounded-lg overflow-hidden">
//             <div className="aspect-w-16 aspect-h-9">
//               <ReactPlayer
//                 url={videoUrl}
//                 controls={true}
//                 width="100%"
//                 height="100%"
//               />
//             </div>
//             <div className="bg-black">
//               <span className="text-white text-xs uppercase px-2">
//                 {videoName}
//               </span>
//             </div>
//             <div className="flex space-x-6 p-1">
//               <div className="flex text-white">
//                 <button>
//                   <img
//                     src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678087-heart-512.png"
//                     className="h-6"
//                     alt=""
//                   />
//                 </button>
//               </div>
//               <div className="flex text-white">
//                 <button>
//                   <img
//                     src="https://www.pngkit.com/png/full/969-9694947_export-computer-icons-import-international-trade-share-icon.png"
//                     className="h-6"
//                     alt=""
//                   />
//                 </button>
//               </div>
//               <div className="flex text-white">
//                 <button>Feedback</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="mb-2">
//         <div className="text-white text-xl font-bold text-center p-2 mt-6 rounded-lg">
//           Other Videos
//         </div>
//         <hr className="md:w-1/5 w-24 h-1 my-0 bg-red-600 border-0 md:my-0 mx-auto dark:bg-red-600" />
//       </div>
//       <RecommendedVideo />
//     </>
//   );
// }

// export default PlayedVideo;
// export default PlayedVideo;

// const PlayedVideo = () => {
//   // const [isPlaying, setIsPlaying] = useState(false);
//   // const [quality, setQuality] = useState("high");
//   // const [brightness, setBrightness] = useState(50);

//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);
//   const videoName = queryParams.get('name');
//   const videoUrl = queryParams.get('url');

//   console.log(videoUrl);

//   return (

//   );
// };

// export default PlayedVideo;

// // const PlayedVideo = () => {
// //   const location = useLocation();
// //   const queryParams = new URLSearchParams(location.search);
// //   const videoName = queryParams.get('name');
// //   const videoUrl = queryParams.get('url');

// //   console.log(videoUrl);

// //   const videoRef = React.useRef(null);

// //   React.useEffect(() => {
// //     const player = videojs(videoRef.current, {
// //       autoplay: true,
// //       controls: true,
// //       fluid: true,
// //       sources: [{
// //         src: videoUrl,
// //         type: 'application/x-mpegURL'
// //       }]
// //     });

// //     return () => {
// //       if (player) {
// //         player.dispose();
// //       }
// //     };
// //   }, [videoUrl]);

// //   return (
// //     <>
// //       <Navbar />
// //       <div className='md:hidden'>
// //         <BottomNav />
// //       </div>

// //       <div className="bg-black my-4 w-full flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
// //         <aside>
// //           <div>
// //             <div className="flex md:w-4/5 border shadow-md rounded-lg overflow-hidden mx-auto">
// //               <div className="flex flex-col m-5">
// //                 <div>
// //                   <div
// //                     data-vjs-player
// //                     className="video-js vjs-default-skin"
// //                   >
// //                     <video ref={videoRef} className="video-js"></video>
// //                   </div>
// //                 </div>
// //                 <div className="bottom-0 w-full bg-gradient-to-r from-black">
// //                   <span className="text-white text-xs uppercase px-2">
// //                     {videoName}
// //                   </span>
// //                 </div>
// //                 <div>
// //                   <div className="flex space-x-3 justify-items-start">
// //                     <div className="flex text-white">
// //                       <button className="">
// //                         <img
// //                           src="https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678087-heart-512.png"
// //                           className="h-6"
// //                           alt=""
// //                         />
// //                       </button>
// //                     </div>
// //                     <div className="flex text-white">
// //                       <button className="">
// //                         <img
// //                           src="https://www.pngkit.com/png/full/969-9694947_export-computer-icons-import-international-trade-share-icon.png"
// //                           className="h-6"
// //                           alt=""
// //                         />
// //                       </button>
// //                     </div>
// //                     <div className="flex text-white">
// //                       <button className="">Feedback</button>
// //                     </div>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           </div>
// //         </aside>
// //       </div>
// //       <div className="mb-2">
// //         <div className="text-white text-xl font-bold text-center p-2 mt-6 rounded-lg">
// //           Other Videos
// //         </div>
// //         <hr className="md:w-1/5 w-24 h-1 my-0 bg-red-600 border-0 md:my-0 mx-auto dark:bg-red-600" />
// //         <RecommendedVideo />
// //       </div>
// //     </>
// //   );
// // };
