import React from 'react'

const TopBar = () => {
    return (
        <nav class="fixed w-screen bg-white border-gray-200 dark:bg-gray-900">
        <div class="flex flex-wrap items-center justify-between p-4">
          <a href="" class="flex">
              <img src="https://cdn-icons-png.flaticon.com/512/93/93634.png" class="md:h-6 h-6" alt="" />
          </a>
          
          <div class="md:block md:w-auto" id="navbar-default">
            <ul class="font-medium flex flex-row md:p-0 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                
                <li className='hidden md:block'>
                    <div class="pt-2 relative mx-auto text-gray-600">
                        <input class="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
                        type="search" name="search" placeholder="Search" />
                        <button type="submit" class="absolute right-0 top-0 mt-4 mr-4">
                            <img src="https://uxwing.com/wp-content/themes/uxwing/download/user-interface/search-icon.png" className='h-6' />
                        </button>
                    </div>
                </li>
                <li className='md:hidden sm:block'>
                    <button type="button" class="relative inline-flex items-center p-3 text-sm font-medium text-center text-white rounded-full">
                    <img src="https://uxwing.com/wp-content/themes/uxwing/download/user-interface/search-icon.png" class="md:h-6 h-6" alt="" />
                    </button>
                </li>
                <li>
                    <button type="button" class="relative inline-flex items-center p-3 text-sm font-medium text-center text-white rounded-full">
                        <img src="https://icons.veryicon.com/png/o/miscellaneous/fine-fillet-icon/notification-bell.png" class="rounded-full md:h-8 h-6" alt="" />
                        <span class="sr-only">Notifications</span>
                        <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -right-0 dark:border-gray-900">20</div>
                    </button>
                </li>
                
                <li>
                    <button type="button" class="relative inline-flex items-center p-3 text-sm font-medium text-center text-white rounded-full">
                        <img src="https://static.thenounproject.com/png/4035887-200.png" class="rounded-full md:h-8 h-6 bg-gray-300" alt="" />
                    </button>
                </li>
                
            </ul>
          </div>
        </div>
      </nav>
  )
}

export default TopBar