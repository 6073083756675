import HorizontalCards from "../components/LiveTVs";
import TendingHorizintal from "../components/TrendingHorizontalVideos";
import VideoOnDemand from "../components/VideosOnDemand";
import VerticalCards from "../components/LiveEvents";
import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import axios from 'axios';

const RecommendedVideo = ({ vodeoCategory }) => {
  let CategoryComponent = null;

  //console.log("gggdgdfdg_________",vodeoCategory)

  if (vodeoCategory === "tvs") {
    CategoryComponent = HorizontalCards;
  } else if (vodeoCategory === "events") {
    CategoryComponent = VerticalCards;
  } else if (vodeoCategory === "vods") {
    CategoryComponent = VideoOnDemand;
  }else {
    CategoryComponent = TendingHorizintal;

  }



  return (
    <div>
    
        <CategoryComponent />
      
    </div>
  );
};

export default RecommendedVideo;












// import React from 'react'
// import MBC from '../assets/images/TV logos/MBC.png'
// import zodiac from '../assets/images/TV logos/zodiak.png'
// import times from '../assets/images/TV logos/times.png'
// import mibawa from '../assets/images/TV logos/mibawa.png'
// import chanco from '../assets/images/TV logos/chanco.png'
// import hope from '../assets/images/TV logos/hope.png'
// import MBC2 from '../assets/images/TV logos/MBC2.png'
// import CAN from '../assets/images/TV logos/CAN.png'
// import Mzati from '../assets/images/TV logos/Mzati.png'
// import Play from '../assets/images/play.png'
// import {MdChevronLeft, MdChevronRight} from 'react-icons/md'

// const RecommendedVideo = () => {
//    const slideLeft = () => {
//        var slider = document.getElementById('slider')
//        slider.scrollLeft = slider.scrollLeft - 500
//    }

//    const slideRight = () => {
//        var slider = document.getElementById('slider')
//        slider.scrollLeft = slider.scrollLeft + 500
//    }

//  return (
//    <div className=''>

//        <div class="flex items-center  bg-black m-auto p-auto">
//                {/* <h1 class="flex py-5 lg:px-20 md:px-10 px-5 lg:mx-40 md:mx-20 mx-5 font-bold text-4xl text-gray-800">
//                    Example
//                </h1> */}
//                <MdChevronLeft className='text-white' onClick={slideLeft} size={50}/>
//            <div id='slider' className='flex overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide pb-10'>

//                <div class="flex flex-nowrap">

//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-mbc">
//                                    <a href="">
//                                        <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-left">
//                                    <div className='items-left'>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8 mt-2'>
//                                        <img class="" src={MBC} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-mbc2">
//                                    <a href="">
//                                    <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-center">
//                                    <div className=''>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8'>
//                                        <img class="" src={MBC2} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-mibawa">
//                                    <a href="">
//                                        <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-center">
//                                    <div className=''>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8'>
//                                        <img class="" src={mibawa} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-zodiak">
//                                    <a href="">
//                                        <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-center">
//                                    <div className=''>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8'>
//                                        <img class="" src={zodiac} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-times">
//                                    <a href="">
//                                        <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-center">
//                                    <div className=''>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8'>
//                                        <img class="" src={times} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-CAN">
//                                    <a href="">
//                                        <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-center">
//                                    <div className=''>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8'>
//                                        <img class="" src={CAN} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-mzati">
//                                    <a href="">
//                                        <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-center">
//                                    <div className=''>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8'>
//                                        <img class="" src={Mzati} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                    <div class="inline-block px-3">
//                        <div class="bg-zinc-950 text-green-500 text-lg font-bold text-center rounded">
//                            <div class="bg-zinc-950 md:w-80 w-60 md:h-54 overflow-hidden md:p-4 p-2 shadow-xl hover:shadow-2xl transition duration-300">
//                                {/* <img class="" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSqHEJmRu0C_Z5Mh9bPQvwStcFLCxODfj0Dig&usqp=CAU" alt="" /> */}
//                                <div className="bg-center bg-cover bg-no-repeat bg-chanco">
//                                    <a href="">
//                                        <img class="md:px-24 px-24 md:py-4 py-8 " src={Play} />
//                                    </a>
//                                </div>
//                                <div class="flex justify-between items-center">
//                                    <div className=''>
//                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
//                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
//                                    </div>
//                                    <div className='md:w-16 w-8'>
//                                        <img class="" src={chanco} alt="" />
//                                    </div>
//                                </div>
//                            </div>
//                        </div>
//                    </div>
//                </div>
//            </div>
//            <MdChevronRight className='text-white' onClick={slideRight} size={50}/>
//        </div>

//    </div>
//  )
// }

// export default RecommendedVideo
