import React, { useState } from "react";
import { useParams } from "react-router-dom";
import payment from "../api/payment";
import PaymentOptionCard from "../components/PaymentOptionCard";
import requestSubscription from '../services/requestSubscription';
import Swal from "sweetalert2";


const PaypalPage = () => {

  const [loading, setLoading] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const price = urlParams.get("price");
  const description = urlParams.get("description");

  const paymentOptions = [
    { id: 1, name: "PayPal", description: "Pay using PayPal.", image: "https://logos-world.net/wp-content/uploads/2020/07/PayPal-Logo.png" },
  ];

  const handlePaymentOptionClick = async (option) => {
    const confirmed = await Swal.fire({
      title: `Do you want to pay ${price} for "${description}"?`,
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (confirmed.isConfirmed) {
      setLoading(true);
      const requestData = {
        userid: userId,
        price: parseFloat(price), // Convert price to float
        description: description,
        payment_gateway: option.name,
      };

      try {
        console.log("Sending subscription request with data:", requestData);
        await payment.makePayment(requestData); // Call makePayment function

        setRequestCompleted(true);
      } catch (error) {
        console.error("Error while processing subscription:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="bg-black">
      <div className="payment-page pr-14 pl-14 text-white mt-2">
        <div className="payment-page-header mb-8 text-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {paymentOptions.map((option) => (
              <PaymentOptionCard
                key={option.id}
                name={option.name}
                description={option.description}
                image={option.image}
                onClick={() => handlePaymentOptionClick(option)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaypalPage;
