import React from "react";
import PropTypes from "prop-types";

const Banner = ({ movie, onPlayClick, onMyListClick }) => {
  function truncate(str, n) {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  console.log("movie?.stream_key ", movie?.thumb_nail);

  return (
    <header
      className="banner backdrop-brightness-100 relative object-cover bg-cover bg-center bg-no-repeat overflow-hidden"
      style={{
        backgroundImage: `url("${movie?.thumb_nail}")`,
      }}
    >
      <div className="banner__contents p-8 absolute bottom-20">

        <div className="banner--text-container">
          <h1 className="text-4xl font-bold text-white bg-black bg-opacity-50 mb-4 p-4">
            {movie?.name}
          </h1>
          {/* Uncomment the following div if you want to display a description */}
          {/* <div className="text-white mt-4">
            <p className="text-sm">{truncate(movie?.overview, 150)}</p>
          </div> */}
        </div>

        <div className="flex space-x-4">
          <button
            className="banner__button bg-red-600 text-white px-6 py-3 rounded-lg font-bold hover:bg-red-500 focus:ring-4 focus:ring-red-300"
            onClick={onPlayClick}
          >
            Play
          </button>
          {/* Uncomment the following button if needed */}
          {/* <button
            className="banner__button bg-white text-black px-6 py-3 rounded-full font-bold hover:bg-gray-300 focus:ring-4 focus:ring-gray-400"
            onClick={onMyListClick}
          >
            My List
          </button> */}
        </div>
      </div>
      <div className="banner--fadeBottom h-24 bg-gradient-to-t from-transparent to-black absolute bottom-0 left-0 right-0"></div>
    </header>
  );
};

Banner.propTypes = {
  movie: PropTypes.object.isRequired,
  onPlayClick: PropTypes.func,
  onMyListClick: PropTypes.func,
};

export default Banner;
