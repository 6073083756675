import React, { useState, useEffect } from "react";
import AllChannels from '../components/AllChannels'
import Navbar from '../components/Navbar'
import WatchHistory from '../components/WatchHistory'
import Footer from '../components/Footer'
import BottomNav from '../components/BottomNav';
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import MalawiDigitalFooter from "../components/MalawiDigital"
import LiveTVJumbotron from '../components/LiveTvJumbotron';


const LiveTV = () => {
  //import React, { useState, useEffect } from "react";

  //import { useNavigate, Link } from "react-router-dom";
//import AuthService from "../services/auth.service";

  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin"); // Redirect to the signup page if user is not logged in
    }
  }, [currentUser, navigate]);
  return (
    
    <div>
      
        <Navbar/>
        <div className='md:hidden'>
        <BottomNav/>
        </div>
        {/* <h1 className='text-white ml-4 py-4 md:text-2xl font-urbanist'>Watch History</h1>
        {/* <hr class="md:w-48 w-24 h-1 ml-4 my-4 bg-red-600 border-0 md:my-10 dark:bg-red-600" /> */}
        {/* <WatchHistory/>  */}
   <LiveTVJumbotron/>
    <div class="flex md:justify-between justify-between pt-6 mt-4" >
       
        <a
          href="#"
          class="text-white hover:text-gray-600 md:text-2xl font-urbanist"
        >
          All Channels
        </a>
      
      </div>
      <hr class="md:w-40 w-24 h-1 my-0 bg-red-600 border-0 md:my-0 dark:bg-red-600 mb-4" />
      


      {/* <h1 className='text-white ml-2 py-2 md:text-2xl font-urbanist'>All Channels</h1>
      <hr class="md:w-48 w-24 h-1 ml-2 my-2 bg-red-600 border-0 md:my-8 dark:bg-red-600" /> */}

        <AllChannels/>
       <MalawiDigitalFooter/>
        <Footer/>
    </div>
  )
}

export default LiveTV