const initialState = {
  data: [],
  loading: false,
  error: null,
};

const trendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TRENDING_DATA":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_TRENDING_DATA_START":
      return { ...state, loading: true, error: null };
    case "FETCH_TRENDING_DATA_SUCCESS":
      return { ...state, data: action.payload, loading: false, error: null };
    case "FETCH_TRENDING_DATA_FAILURE":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default trendingReducer;
