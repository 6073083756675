import { combineReducers } from "redux";
import eventsReducer from "./reducers/eventsSlice";
import trendingReducer from "./reducers/trendingSlice";
import loadingDataReducer from "./reducers/loadingDataReducer";

const rootReducer = combineReducers({
  events: eventsReducer,
  trending: trendingReducer,
  loadingData: loadingDataReducer,
});

export default rootReducer;
