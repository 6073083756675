import React, { useState, useEffect } from "react";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";

import mdbn from "../assets/images/TV logos/mdbn.png";
import Play from "../assets/images/play.png";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import { Link } from "react-router-dom";
import authHeader from "../services/auth-header"; // Replace with thconst headers = authHeader();e actual path to the file containing the exported function
import CurrentTimestamp from "./ShowTime";
// import LiveTvJumbotron from '../components/LiveTvsJumbotron'
import viewCountService from "../services/viewCountService"; // Import the service

const HorizontalCards = () => {
  const fectTvUrl = KanemaRequests.fetTvs;
  const [LiveTVideos, setLiveTVideos] = useState([]);
  const category = "tvs";

  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  useEffect(() => {
    async function fetchLiveTvData() {
      const headers = authHeader();

      const response = await axios.get(fectTvUrl, { headers });

      let data = response.data;

      // Filter videos with visibility true and lifetime more than zero
      data = data.filter(
        (video) => video.status.visibility && video.status.lifetime > 0
      );

      // Sort the data based on the number of views (highest to lowest)
      data.sort((a, b) => b.views.length - a.views.length);

      setLiveTVideos(data);
    }

    fetchLiveTvData();
  }, [fectTvUrl]);

  const handleLinkClick = async (id) => {
    try {
      // Call the updateViewCount function from the service
      await viewCountService.updateViewCount(category, id);
    } catch (error) {
      // Handle errors if needed
      console.error("Error updating view count:", error);
    }
  };
  return (
    <div className="">
      <div className="flex items-center bg-black">
        <MdChevronLeft
          onClick={slideLeft}
          size={30}
          className="cursor-pointer text-white"
        />
        <div
          id="slider"
          className="flex overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide pb-15"
        >
          <div className="flex flex-nowrap">
            {LiveTVideos.map((tv) => (
              <div className="inline-block px-0.5 sm:px-0.5"> {/* Adjust padding */}
                <Link
                  onClick={() => handleLinkClick(tv._id)}
                  to={`/currentVideo?name=${tv.name}&id=${tv._id}&category=${category}&url=${tv.stream_key}`}
                >
                  <div className="bg-zinc-950 text-green-500 text-sm font-bold text-center rounded">
                    <div className="bg-zinc-950 w-56 h-44 md:w-64 md:h-52 overflow-hidden p-0.5 flex flex-col justify-center items-center shadow-xl hover:shadow-2xl transition duration-300">
                      <div
                        className="bg-zinc-950 w-full h-3/4 overflow-hidden p-1"
                        style={{
                          backgroundImage: `url(${tv.thumb_nail})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <a href="" className="flex items-center justify-center h-full">
                          <img
                            className="px-16 py-2"
                            src={Play}
                          />
                        </a>
                      </div>
                      <div className="flex justify-between items-left w-full">
                        <div className="items-left text-center">
                          <h1 className="mt-2 font-semibold text-xs text-white">
                            {tv.name}
                          </h1>
                          <p className="mt-2 text-neutral-600 text-xs">
                            <CurrentTimestamp />
                          </p>
                        </div>
                        <div className="w-8 mt-2">
                          <img className="" src={mdbn} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <MdChevronRight
          onClick={slideRight}
          size={30}
          className="cursor-pointer text-white"
        />
      </div>
    </div>
  );
};
export default HorizontalCards;
