import axios from "axios";
import Swal from "sweetalert2";

const baseURL = "https://kanemaonline.com/clone/billing";
const SUBSCRIPTION_ENDPOINT = `${baseURL}/subscriptions`;

const payment = {
  async makePayment(requestBody) {
    try {
      console.log("Sending subscription request...");

      const response = await axios.post(SUBSCRIPTION_ENDPOINT, requestBody);

      console.log("Response:", response);

      if (response.status === 201 && response.data.status === "success") {
        const data = response.data;
        console.log("ref....", data.ref);
        localStorage.setItem("paymentRef", data.ref);
        window.location.href = response.data.message;
      } else {
        // Check if the response status is not successful and subscription already present
        if (
          response.data.status === "unsuccessful" &&
          response.data.message.includes("subscription already present")
        ) {
          // Show specific message for subscription already present
          showErrorAlert("Subscription already present!");
        } else {
          // Show generic error message for other errors
          showErrorAlert( response.data.message);
        }
      }
    } catch (error) {
      console.error("Error requesting subscription:", error);
      showErrorAlert(
        "An error occurred while processing your request. Please try again later."
      );
    }
  },

  async createPaymentSuccess(ref) {
    try {
      const response = await axios.post(`${baseURL}/create-payment-success`, {
        ref: ref,
        transactionid: "",
      });
      const data = response.data;
      console.log(data.message);
    } catch (error) {
      console.error("Error creating payment success:", error);
      showErrorAlert(
        "An error occurred while creating payment success. Please try again later."
      );
    }
  },
};

const showErrorAlert = (errorMessage) => {
  console.log("Showing error alert:", errorMessage);
  Swal.fire({
    icon: "error",
    title: errorMessage,
    confirmButtonColor: "#000000",
    background: "#ffffff",
    customClass: {
      container: "black-white-theme",
    },
    theme: "borderless",
  });
};

export default payment;
