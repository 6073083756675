import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import AuthService from "../services/auth.service";
import authHeader from '../services/auth-header'; // Replace with the actual path to the file containing the exported function

import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import CurrentTimestamp from '../components/ShowTime'
import mdbn from "../assets/images/TV logos/mdbn.png";
import Play from "../assets/images/play.png";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import viewCountService from "../services/viewCountService"; // Import the service


const AllChannels = () => {

    //   import React, { useState, useEffect } from "react";
// import { useNavigate, Link } from "react-router-dom";
// import AuthService from "../services/auth.service";

  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin"); // Redirect to the signup page if user is not logged in
    }
  }, [currentUser, navigate]);
  const category = "tvs";

  const fectTvUrl = KanemaRequests.fetTvs;
  const [LiveTVideos, setLiveTVideos] = useState([]);

  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };
  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

useEffect(() => {
  async function fetchLiveTvData() {

    const headers = authHeader();

    // const accessToken = localStorage.getItem('accessToken'); // Retrieve the access token from local storage
    // const headers = {
    //   Authorization: `Bearer ${accessToken}`,
    // };
    const response = await axios.get(fectTvUrl, { headers });

    const liveTvData = response.data.filter((liveTv )=> {
      return liveTv.status.lifetime > 0 && liveTv.status.type === "normal"});
    // Sort the liveTvData based on views in descending order
    // liveTvData.sort((a, b) => b.views - a.views);
    liveTvData.sort((a, b) => b.views.length - a.views.length);
    setLiveTVideos(liveTvData);

  }
  fetchLiveTvData();
}, [fectTvUrl]);


 const handleLinkClick = async (id) => {
    try {
      // Call the updateViewCount function from the service
      await viewCountService.updateViewCount(category,id);
    
    } catch (error) {
      // Handle errors if needed
      console.error("Error updating view count:", error);
    }
  };
  return (
<div className="container mx-auto md:mb-1 md: mt-6">
  <div className="grid-container">
    <div className="">
      <div className="grid-wrapper">
        <div className="grid gap-3 md:grid-cols-6 grid-cols-2">
          {LiveTVideos.map((tv) => (
            <Link
             onClick={() => handleLinkClick(tv._id)} 
              to={`/currentVideo?name=${tv.name}&id=${tv._id}&category=${category}&url=${tv.stream_key}`}
              key={tv._id}
            >
              <div className="col-span-12 rounded-lg sm:col-span-1 m-1">
                <img
                  alt=""
                  src={tv.thumb_nail}
                  className="object-cover rounded-l h-52 w-full"
                />
                <div className="">
                  <p className="mt-1 text-white md:text-sm text-xs">
                    {tv.name.length > 20 ? `${tv.name.substring(0, 20)}...` : tv.name}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>

  <div className="mb-2"></div>
</div>



  );
};

export default AllChannels;



        {/* 


        
                    <div class="inline-block px-3">
                        
                        <div class="container rounded mx-auto p-6 bg-zinc-950 w-80 h-54 overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                            <img class="" src={poster} alt="" />
                            
                            <div class="flex justify-between items-left">
                                <div className='items-left'>
                                    <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
                                    <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
                                </div>
                                <div className='md:w-16 w-8 mt-2'>
                                    <img class="" src={chanco} alt="" />
                                </div>
                            </div>
                    </div>
                </div>
                <div class="inline-block px-3">
                        
                        <div class="container rounded mx-auto p-6 bg-zinc-950 w-80 h-54 overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                            <img class="" src={poster} alt="" />
                            
                            <div class="flex justify-between items-left">
                                <div className='items-left'>
                                    <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
                                    <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
                                </div>
                                <div className='md:w-16 w-8 mt-2'>
                                    <img class="" src={mibawa} alt="" />
                                </div>
                            </div>
                    </div>
                </div>
                    <div class="inline-block px-3">
                        
                            <div class="container rounded mx-auto p-6 bg-zinc-950 w-80 h-54 overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                                <img class="" src={poster} alt="" />
                                
                                <div class="flex justify-between items-left">
                                    <div className='items-left'>
                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
                                    </div>
                                    <div className='md:w-16 w-8 mt-2'>
                                        <img class="" src={CAN} alt="" />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="inline-block px-3">
                        
                            <div class="container rounded mx-auto p-6 bg-zinc-950 w-80 h-54 overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                                <img class="" src={poster} alt="" />
                                
                                <div class="flex justify-between items-left">
                                    <div className='items-left'>
                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
                                    </div>
                                    <div className='md:w-16 w-8 mt-2'>
                                        <img class="" src={Mzati} alt="" />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="inline-block px-3">
                        
                            <div class="container rounded mx-auto p-6 bg-zinc-950 w-80 h-54 overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                                <img class="" src={poster} alt="" />
                                
                                <div class="flex justify-between items-left">
                                    <div className='items-left'>
                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
                                    </div>
                                    <div className='md:w-16 w-8 mt-2'>
                                        <img class="" src={times} alt="" />
                                    </div>
                                </div>
                        </div>
                    </div> */}
        {/* <div class="inline-block px-3">
                        
                            <div class="container rounded mx-auto p-6 bg-zinc-950 w-80 h-54 overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                                <img class="" src={poster} alt="" />
                                
                                <div class="flex justify-between items-left">
                                    <div className='items-left'>
                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
                                    </div>
                                    <div className='md:w-16 w-8 mt-2'>
                                        <img class="" src={MBC2} alt="" />
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="inline-block px-3">
                        
                            <div class="container rounded mx-auto p-6 bg-zinc-950 w-80 h-54 overflow-hidden shadow-xl hover:shadow-2xl transition duration-300">
                                <img class="" src={poster} alt="" />
                                
                                <div class="flex justify-between items-left">
                                    <div className='items-left'>
                                        <p class="mt-2 text-neutral-600 md:text-sm text-xs">12:00 - 13:00</p>
                                        <h1 class="md:font-semibold md:text-sm text-xs text-white">Breaking News</h1>
                                    </div>
                                    <div className='md:w-16 w-8 mt-2'>
                                        <img class="" src={hope} alt="" />
                                    </div>
                                </div>
                        </div>
                    </div> */}