import { sendPutRequest } from "../utils/views";

const accessToken = localStorage.getItem("accessToken");
const email = localStorage.getItem("email");


const updateViewCount = async (vodeoCategory, videoID) => {
  const url = `https://kanemaonline.com/${vodeoCategory}/${videoID}/views`;

  console.log(url);

  try {
    console.log(`PUT Request URL: ${url}`);

    const viewsData = {
      email,
      views: 1,
      created_at: new Date().toISOString(),
    };

    console.log(`Views Data: ${JSON.stringify(viewsData)}`);

    const updateResponse = await sendPutRequest(url, accessToken, viewsData);
    console.log("Views updated successfully",updateResponse);

    if (updateResponse.statusCode === 200) {
      console.log("Views updated successfully");
    } else {
    }
  } catch (e) {
    console.error(`Error: ${e}`);
  }
};

const viewCountService = {
  updateViewCount,
};

export default viewCountService;
