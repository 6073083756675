import React from "react";

const DebtCreditCardPage = () => (
  <div className="page-container flex flex-col items-center">
    <h1 className="text-3xl font-bold mb-8">Payment Information</h1>
    
    <form className="bg-gray-300 p-8 rounded-lg shadow-md">
      <label className="block text-gray-700 text-lg mb-2">Cardholder's Name:</label>
      <input
        type="text"
        className="border rounded-lg px-4 py-2 mb-4 w-full"
        placeholder="Enter cardholder's name"
      />

      <label className="block text-gray-700 text-lg mb-2">Card Number:</label>
      <input
        type="text"
        className="border rounded-lg px-4 py-2 mb-4 w-full"
        placeholder="Enter card number"
      />

      <div className="flex">
        <div className="flex-1 pr-2">
          <label className="block text-gray-700 text-lg mb-2">Expiration Date:</label>
          <input
            type="text"
            className="border rounded-lg px-4 py-2 mb-4 w-full"
            placeholder="MM/YYYY"
          />
        </div>

        <div className="flex-1 pl-2">
          <label className="block text-gray-700 text-lg mb-2">CVV:</label>
          <input
            type="text"
            className="border rounded-lg px-4 py-2 mb-4 w-full"
            placeholder="Enter CVV"
          />
        </div>
      </div>

      <button
        type="submit"
        className="bg-gray-500 text-white rounded-lg px-6 py-3 text-lg hover:bg-gray-700"
      >
        Pay Now
      </button>
    </form>
  </div>
);

export default DebtCreditCardPage;
