import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { TabPanel, useTabs } from "react-headless-tabs";
import TabSelector from '../components/TabSelector';
import MobileMoney from '../pages/MobileMoney';
import PaypalPage from '../pages/PaypalPage';
import DebtCreditCardPage from '../pages/DebtCreditCardPage';
import logo from '../assets/images/image 8.png';

const TabComponent = () => {
    const [selectedTab, setSelectedTab] = useTabs([
        "Mobile",
        "Paypal",
        "Debt/Credit card",
    ]);


    const [mobileTabActive, setMobileTabActive] = useState(true);
    const [paypalTabActive, setPaypalTabActive] = useState(true);
    const [debtCreditCardTabActive, setDebtCreditCardTabActive] = useState(true);
    const handleTabClick = (tab) => {
        setSelectedTab(tab);

        if (tab === "Mobile" && mobileTabActive) {

        } else if (tab === "Paypal" && paypalTabActive) {

        } else if (tab === "Debt/Credit card" && debtCreditCardTabActive) {

        }
    };

    return (
        <div className="bg-black">
            <header className="flex flex-col md:flex-row items-center justify-between md:pl-60 md:pr-60">

                <div className="flex items-center mb-2 md:mb-0">
                        <img src={logo} alt="Your Company" width="200" height="250" />
                </div>


                <div className="text-2xl font-bold md:text-left md:mx-4 text-white">
                    Payment Gateway
                </div>


            </header>


            <div className="text-white text-center mb-4">
                <p className="text-l ">Choose your payment method</p>
            </div>

            <div className="flex flex-wrap justify-center pt-4 pb-2  md:space-x-72">
                <TabSelector
                    isActive={selectedTab === "Mobile"}
                    onClick={() => handleTabClick("Mobile")}
                    className={`px-4 py-2 border rounded ${mobileTabActive ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                >
                    Mobile Money
                </TabSelector>
                <TabSelector
                    isActive={selectedTab === "Paypal"}
                    onClick={() => handleTabClick("Paypal")}
                    className={`px-4 py-2 border rounded ${paypalTabActive ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                >
                    Paypal
                </TabSelector>
                <TabSelector
                    isActive={selectedTab === "Debt/Credit card"}
                    onClick={() => handleTabClick("Debt/Credit card")}
                    className={`px-4 py-2 border rounded ${debtCreditCardTabActive ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                >
                    Debt/Credit card
                </TabSelector>
            </div>



            <div className=" md:pl-48 md:pr-48 pt-10">
                {selectedTab === "Mobile" && <MobileMoney />}
                {selectedTab === "Paypal" && <PaypalPage />}
                {selectedTab === "Debt/Credit card" && <DebtCreditCardPage />}
            </div>
        </div>
    );
};

export default TabComponent;
