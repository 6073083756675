import React, { useState, useEffect } from 'react';

function CurrentTimestamp() {
  const [currentTimestamp, setCurrentTimestamp] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const currentDate = new Date();
      setCurrentTimestamp(currentDate.toLocaleString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <p className="text-xs">{currentTimestamp}</p> {/* Reduce text size using text-xs class */}
    </div>
  );
}

export default CurrentTimestamp;
