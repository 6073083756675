import "./FeaturedMovie.css";
import { FaPlay, FaPlus, FaSearch } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import { Link } from "react-router-dom";
import authHeader from "../services/auth-header"; // Replace with thconst headers = authHeader();e actual path to the file containing the exported function
import viewCountService from "../services/viewCountService"; // Import the service
// Import other necessary dependencies

const FeaturedMovie = ({ item }) => {
  const fetchTrendingUrl = KanemaRequests.fetTrends;
  const [trendingVideos, setTrendingVideos] = useState([]);
  const category = "trends";

  useEffect(() => {
    async function fetchTrendingData() {
      try {
        const headers = authHeader();
        const response = await axios.get(fetchTrendingUrl, { headers });
        const videos = response.data;

        let filteredVideos = videos.filter(
          (video) => video.status.lifetime > 0 && video.status.type === "normal"
        );

        const liveVideos = videos.filter(
          (video) => video.status.type === "live"
        );
        if (liveVideos.length > 0) {
          filteredVideos = liveVideos;
        }

        if (filteredVideos.length > 0) {
          const firstVideo = filteredVideos[0];
          setTrendingVideos([firstVideo]);
        }
      } catch (error) {
        console.error("Error fetching trending data:", error);
      }
    }

    fetchTrendingData();
  }, [fetchTrendingUrl]);

  const handleLinkClick = async (id) => {
    try {
      await viewCountService.updateViewCount(category, id);

      if (trendingVideos.length > 0 && !trendingVideos[0].status.publish) {
        alert("This video is not published yet.");
      }
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };

  const imageUrl = trendingVideos[0]?.thumb_nail || "";

  const altText = trendingVideos.length > 0 ? trendingVideos[0].name : "";

  return (
  <section
    className="featured"
    style={{
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundImage: `url(${imageUrl})`,
    }}
  >
    <div className="featured--vertical">
      <div className="featured--horizontal">
        {/* Additional elements */}
        {/* <div className="featured--genres"><strong>Gêneros:</strong> {genres.join(', ')}</div> */}
      </div>

      {/* Move text and buttons to the bottom */}
      <div className="mt-[-120px] m-10">

        <div className="featured--name text-4xl font-bold text-white bg-black bg-opacity-10 mb-4 p-4">{altText}</div>
        <div className="featured--info">
          {/* <div className="featured--points">{item.vote_average} pontos</div> */}
          {/* <div className="featured--year">{firstDate.getFullYear()}</div> */}
          {/* <div className="featured--seasons">{item.number_of_seasons} temporada{item.number_of_seasons !== 1 ? 's' : ''}</div> */}
        </div>
        {/* <div className="featured--description">{item.overview}</div> */}
        <div className="featured--buttons">
          <div className="flex flex-wrap">
            {/* Play button */}
            {trendingVideos.length > 0 && trendingVideos[0].status.publish ? (
              <Link
                to={`/currentVideo?name=${trendingVideos[0]?.name || ""}&id=${trendingVideos[0]?.id || ""}&url=${trendingVideos[0]?.stream_key || ""}&category=${category}`}
                onClick={() => handleLinkClick(trendingVideos[0]?.id || "")}
                className="rounded inline-flex md:mr-2 justify-center items-center md:py-3 md:px-5 px-2 text-base md:text-xl font-bold text-white md:rounded-xl bg-red-600 hover:bg-red-500 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900"
              >
                <svg
                  className="w-6 h-6 dark:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  ></path>
                </svg>
                Play
              </Link>
            ) : (
              // Not Published button
              <div className="rounded inline-flex md:mr-2 justify-center items-center md:py-3 md:px-5 px-2 text-base md:text-xl font-bold text-white md:rounded-xl bg-red-600 opacity-50 cursor-not-allowed">
                <svg
                  className="w-6 h-6 dark:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  ></path>
                </svg>
                Not Published
              </div>
            )}

            {/* Trending button */}
            <div className="flex ">
              <h2 className="rounded inline-flex justify-center items-center px-1 md:py-3 md:px-5 text-base md:text-xl font-bold text-black bg-white md:rounded-xl border border-white focus:ring-4 focus:ring-gray-400">
                Trending
              </h2>
              {/* Additional elements */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  );
};

export default FeaturedMovie;



