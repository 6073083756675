import React, { useEffect, useState } from "react";
import axios from "axios";

function getAccessToken() {
  const accessToken = localStorage.getItem("accessToken");

  //console.log(userId);

  if (!accessToken) {
    return null; // Token not found in localStorage
  }

  // Decode the access token
  const decodedToken = decodeJWT(accessToken);

  // Check if the token has expired
  const currentTime = Date.now() / 1000; // Convert to seconds
  if (decodedToken.exp < currentTime) {
    // Token has expired
    return handleTokenExpiration();
  }

  return accessToken; // Valid access token
}

function decodeJWT(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

async function handleTokenExpiration() {
  const refreshToken = localStorage.getItem("refresh_token");
  const userId = localStorage.getItem("user");

  // console.log(refreshToken);
  // console.log(userId);
  // console.log(accessToken4);
  try {
    const url = "https://kanemaonline.com/auth/refresh";

    const headers = {
      Authorization: `Bearer ${refreshToken}`,
      "Content-Type": "application/json",
    };

    const data = {
      userid: userId,
      refresh_token: refreshToken,
    };

    const response = await axios.post(url, data, { headers });

    if (response) {
      // console.log(response.data.accessToken);
      // console.log(response.data.refresh_token);
      localStorage.setItem("accessToken", response.data.accessToken);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      return response.data.accessToken;
    } else {
      throw new Error(
        `Failed to refresh token. Status code: ${response.status}`
      );
    }
  } catch (error) {
    throw new Error(`Failed to refresh token: ${error.message}`);
  }
}

// function handleTokenExpiration() {
//   const refreshToken = localStorage.getItem("refresh_token");
//   if (!refreshToken) {
//     return null; // Refresh token not found in localStorage
//   }

//   const userId = localStorage.getItem("user");

//   // Make a refresh request using the refresh token and user id
//   const refreshTokenEndpoint = "https://kanemaonline.com/auth/refresh";
//   const headers = { Authorization: `Bearer ${refreshToken}` };
//   const body = { userid: userId, refresh_token: refreshToken };

//   const refreshRequest = {
//     headers: {
//       headers,
//     },
//     body: body,
//   };
//   return axios
//     .post(refreshTokenEndpoint, refreshRequest)
//     .then((response) => {
//       const newAccessToken = response.data.accessToken;
//       const newRefreshToken = response.data.refreshToken;

//       // Store the new tokens in localStorage
//       localStorage.setItem("accessToken", newAccessToken);
//       localStorage.setItem("refresh_token", newRefreshToken);

//       return newAccessToken;
//     })
//     .catch((error) => {
//       console.error("Error refreshing token:", error);
//       return null; // Error occurred while refreshing token
//     });
// }

export { getAccessToken, handleTokenExpiration };

// const refreshTokenRequest = async (userId, refreshToken) => {
//   try {
//     const url = 'https://kanemaonline.com/auth/refresh';
//     const headers = { 'Authorization': `Bearer ${refreshToken}` };
//     const body = { 'userid': userId, 'refresh_token': refreshToken };

//     // Perform the refresh token request
//     console.log('Performing refresh token request...');
//     const response = await axios.post(url, body, { headers });

//     if (response.status === 200 || response.status === 201) {
//       const tokens = response.data;
//       const newAccessToken = tokens.accessToken;
//       const newRefreshToken = tokens.refresh_token;
//       console.log('Token refreshed successfully.');

//       // Store the refreshed tokens
//       localStorage.setItem('accessToken', newAccessToken);
//       localStorage.setItem('refresh_token', newRefreshToken);

//       return newAccessToken;
//     } else {
//       console.log('Failed to refresh token. Status code:', response.status);
//       throw new Error('Failed to refresh token');
//     }
//   } catch (error) {
//     console.log('Failed to refresh token:', error);
//     throw error;
//   }
// };

// const makeAuthenticatedRequest = async () => {
//   const tokenExpirationThreshold = 60; // Threshold in seconds
//   const userIdFromPrefs = localStorage.getItem('user');
//   const accessTokenFromPrefs = localStorage.getItem('accessToken');
//   const refreshTokenFromPrefs = localStorage.getItem('refresh_token');

//   if (!userIdFromPrefs || !accessTokenFromPrefs || !refreshTokenFromPrefs) {
//     // Handle missing user data from local storage
//     console.log('Missing user data from local storage');
//     return;
//   }

//   // Decode the JWT to get the expiration time
//   const parts = accessTokenFromPrefs.split('.');
//   const payload = JSON.parse(atob(parts[1]));
//   const expirationTime = new Date(payload.exp * 1000);

//   // Check if the JWT is expired
//   if (expirationTime < new Date().getTime() + tokenExpirationThreshold * 1000) {
//     // Refresh the token
//     console.log('JWT is expired. Refreshing token...');
//     const refreshedToken = await refreshTokenRequest(userIdFromPrefs, refreshTokenFromPrefs);

//     // Make the request using the refreshed token
//     console.log('Token refreshed successfully. Making request using refreshed token...');
//     return refreshedToken;
//   }

//   // JWT is still valid, return it
//   console.log('JWT is still valid.');
//   return accessTokenFromPrefs;
// };

// const JWTCheck = () => {
//   const [jwtAccessToken, setJwtAccessToken] = useState(null);

//   useEffect(() => {
//     makeAuthenticatedRequest()
//       .then((accessToken) => {
//         setJwtAccessToken(accessToken);
//       })
//       .catch((error) => {
//         // Handle error
//         console.log('Failed to get access token:', error);
//       });
//   }, []);

//   // Getter function to access the JWT access token
//   const getJwtAccessToken = () => {
//     return jwtAccessToken;
//   };

//   return <div>JWT Check</div>;
// };

// export default JWTCheck;
// export { makeAuthenticatedRequest, getJwtAccessToken };
