import React from "react";
import PropTypes from "prop-types";

const PaymentCard = ({ label, value }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
      <p className="text-sm text-gray-600 mb-1">{label}</p>
      <p className="text-lg font-semibold">{value}</p>
    </div>
  );
};

PaymentCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default PaymentCard;
