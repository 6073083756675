
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import logo from "../assets/images/image 8.png";

const VerifyPasswordTopBar = () => {
  return (
    <div className="relative">
      <nav class="py-4 px-4 bg-black">
        <div class="mx-auto mt-2">
          <div class="flex justify-between">
            <div class="flex space-x-4">
              <div class="md:mt-0">
                <Link to="/">
                <img
                  class="mx-auto h-auto w-32"
                  src={logo}
                  alt="Your Company"
                />
                </Link>
              </div>
            </div>
            <div class="md:flex mt-3 items-center space-x-1">
              <Link to="/signin">
                <a
                  href=""
                  className="py-2 px-3 bg-black hover:bg-white text-white hover:text-black rounded-full transition duration-300 border-white border-2"
                  style={{ fontWeight: "bold", border: "1px solid white" }}
                >
                  Login
                </a>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default VerifyPasswordTopBar;
