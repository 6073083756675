import React, { useState, useEffect } from "react";
import Play from "../assets/images/play.png";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import { Link } from "react-router-dom";
import authHeader from "../services/auth-header"; // Replace with thconst headers = authHeader();e actual path to the file containing the exported function
import viewCountService from "../services/viewCountService"; // Import the service
import Swal from "sweetalert2";

const VideoOnDemand = ({}) => {
  const fetchVideoOnDemandUrl = KanemaRequests.fetVod;
  const category = "vods";
  const [videosOnDemand, setvideosOnDemand] = useState([]);

  const slideToLeft = () => {
    var slider = document.getElementById("VODslider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideToRight = () => {
    var slider = document.getElementById("VODslider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  useEffect(() => {
    async function fetchVideoOnDemand() {
      try {
        const headers = authHeader();
        const response = await axios.get(fetchVideoOnDemandUrl, { headers });
        const videos = response.data;

        // Filter the videos based on the specified conditions
        const filteredVideos = videos.filter((video) => {
          return (
            video.status.lifetime > 0 &&
            video.status.type === "normal" &&
            video.status.visibility
          );
        });

        // Sort the filtered videos based on views in descending order
        filteredVideos.sort((a, b) => b.views.length - a.views.length);

        setvideosOnDemand(filteredVideos);
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching video on demand data:", error);
      }
    }

    fetchVideoOnDemand();
  }, [fetchVideoOnDemandUrl]);

  // Function to handle link click and update view count
  const handleLinkClick = async (id, isPublished) => {
    try {
      if (!isPublished) {
        // Show alert if the video is not published
        await Swal.fire({
          icon: "warning",
          title: "Coming soon!",
          text: "The video you are trying to play is not yet published.",
          customClass: {
            container: "bg-black text-white",
            title: "text-black",
            content: "text-black",
            confirmButton: "bg-black text-white hover:bg-gray-700",
          },
          confirmButtonColor: "#000000",
          width: 400,
          height: 20,
          animation: false,
        });

        return;
      }

      // Call the updateViewCount function from the service
      await viewCountService.updateViewCount(category, id);
    } catch (error) {
      // Handle errors if needed
      console.error("Error updating view count:", error);
    }
  };

  return (
    <div className="">
      <div className="flex items-center bg-black m-auto p-auto">
        <MdChevronLeft onClick={slideToLeft} size={60} />
        <div
          id="VODslider"
          className="flex overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide pb-15"
        >
          <div className="flex flex-nowrap">
            {videosOnDemand.map((trendingVideo) => (
              <div
                className="inline-block px-0.5 sm:px-0.5"
                key={trendingVideo._id}
              >
                <div>
                  {/* Check if the video is published */}
                  {trendingVideo.status.publish ? (
                    <Link
                      onClick={() =>
                        handleLinkClick(
                          trendingVideo._id,
                          trendingVideo.status.publish
                        )
                      }
                      to={`/currentVideo?name=${trendingVideo.name}&id=${trendingVideo._id}&url=${trendingVideo.stream_key}&category=${category}`}
                    >
                      <div className="bg-zinc-950 text-green-500 text-sm font-bold text-center rounded">
                        <div className="bg-zinc-950 w-56 h-44 md:w-64 md:h-52 overflow-hidden p-0.5 flex flex-col justify-center items-center shadow-xl hover:shadow-2xl transition duration-300">
                          <div
                            className="bg-zinc-950 w-full h-full overflow-hidden p-1"
                            style={{
                              backgroundImage: `url(${trendingVideo.thumb_nail})`,
                              backgroundPosition: "center",
                              backgroundSize: "100% 100%", // Stretch the image
                              backgroundRepeat: "no-repeat",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <a
                              href=""
                              className="flex items-center justify-center h-full"
                            >
                              <img className="px-16 py-2" src={Play} />
                            </a>
                          </div>
                          <div className="flex justify-between items-left w-full">
                            <div className="items-left text-center">
                              <h1 className="mt-2 font-semibold text-xs text-white">
                                {trendingVideo.name}
                              </h1>
                            </div>
                            <div className="w-8 mt-2"></div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ) : (
                    // If not published, show a disabled link

                    <div
                      onClick={() =>
                        handleLinkClick(
                          trendingVideo._id,
                          trendingVideo.status.publish
                        )
                      }
                      className="cursor-not-allowed"
                    >
                      <div className="bg-zinc-950 text-green-500 text-sm font-bold text-center rounded cursor-not-allowed">
                        <div className="bg-zinc-950 w-56 h-44 md:w-64 md:h-52 overflow-hidden p-0.5 flex flex-col justify-center items-center hover:shadow-2xl transition duration-300  cursor-not-allowed">
                          <div
                            className="bg-zinc-950 w-full h-full overflow-hidden p-1"
                            style={{
                              backgroundImage: `url(${trendingVideo.thumb_nail})`,
                              backgroundPosition: "center",
                              backgroundSize: "100% 100%", // Stretch the image
                              backgroundRepeat: "no-repeat",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <a className="flex items-center justify-center h-full">
                              <img className="px-16 py-2" src={Play} />
                            </a>
                          </div>
                          <div className="flex justify-between items-left w-full">
                            <div className="items-left text-center">
                              <h1 className="mt-2 font-semibold text-xs text-white">
                                {trendingVideo.name}
                              </h1>
                            </div>
                            <div className="w-8 mt-2"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <MdChevronRight onClick={slideToRight} size={60} />
      </div>
    </div>
  );
};

export default VideoOnDemand;
