import React, { useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEventDataStart,
  fetchEventDataSuccess,
  fetchEventDataFailure,
} from "../actions/eventsActions";
import config from "../api/config"; // Make sure you import the config file
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import VideoContainer from "../components/VideoContainerMobile";
import VideoContainerDesktop from "../components/VideoContainerDesktop";
import { Link } from "react-router-dom";
import viewCountService from "../services/viewCountService";

const LiveEvents = ({ category }) => {
  const dispatch = useDispatch();
  const liveEventsState = useSelector((state) => state.events);
  const { data: liveEventVideos, loading, error } = liveEventsState;
  const accessToken = localStorage.getItem("accessToken");

  const fetchLiveEventsUrl = `${config.apiBaseUrl}/events`;

   category="events" 

  const slideToLeft = () => {
    var slider = document.getElementById("LiveEventSlider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideToRight = () => {
    var slider = document.getElementById("LiveEventSlider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchEventDataStart());

      try {
        const response = await axios.get(fetchLiveEventsUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        let data = response.data;

        // Filter videos with visibility true and lifetime more than zero
        data = data.filter(
          (video) => video.status.visibility && video.status.lifetime > 0
        );

        // Sort the data based on the number of views (highest to lowest)
        data.sort((a, b) => b.views.length - a.views.length);
        dispatch(fetchEventDataSuccess(data));
      } catch (error) {
        console.error(`Error fetching ${category} data:`, error);
        dispatch(fetchEventDataFailure(error.message));
      }
    };

    fetchData();
  }, [dispatch, fetchLiveEventsUrl, category, accessToken]);

  const handleLinkClick = async (id) => {
    try {
      await viewCountService.updateViewCount(category, id);
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };

  return (
    <div className="">
      <div className="flex items-center bg-black m-auto p-auto">
        <MdChevronLeft onClick={slideToLeft} size={160} />
        <div
          id="LiveEventSlider"
          className="flex overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide pb-10"
        >
          {loading && <p>Loading...</p>}
          {Array.isArray(liveEventVideos) &&
            liveEventVideos.map((eventVideo) => (
              <div className="flex flex-nowrap" key={eventVideo._id}>
                <Link
                  onClick={() => handleLinkClick(eventVideo._id)}
                  to={`/currentVideo?name=${eventVideo.name}&id=${eventVideo._id}&category=${category}&url=${eventVideo.video_url}`}
                >
                  <div className="block sm:hidden">
                    <VideoContainer
                      imageUrl={eventVideo.thumb_nail}
                      imageName={eventVideo.name}
                    />
                  </div>
                  <div className="hidden sm:block">
                    <VideoContainerDesktop
                      imageUrl={eventVideo.thumb_nail}
                      imageName={eventVideo.name}
                    />
                  </div>
                </Link>
              </div>
            ))}
        </div>
        <MdChevronRight onClick={slideToRight} size={160} />
      </div>
    </div>
  );
};

export default LiveEvents;
