
import React, { useEffect, useState } from "react";
import Navbar from "../components/VerifyPasswordTopBar";
import Footer from "../components/Footer";
import PaymentCard from "../components/PaymentCard";
import "./policy.css";
import Typography from "../components/Typography";
import Swal from "sweetalert2";
import logo from '../assets/images/image 8.png';
import payment from "../api/payment";



const DPOSuccessRedirect = () => {

  
  // State for dynamic data
  const [dynamicData, setDynamicData] = useState({
    transId: "",
    transactionToken: "",
    companyRef: "",
    ccdApproval: "",
    pnrId: "",
  });

  useEffect(() => {


    // Function to extract data from the URL
    const extractDataFromURL = () => {
      // Access the query string part of the URL
      const queryString = window.location.search;


      const urlParams = new URLSearchParams(queryString);

      const transId = urlParams.get('TransID');
      const transactionToken = urlParams.get('TransactionToken');
      const companyRef = urlParams.get('CompanyRef');
      const ccdApproval = urlParams.get('CCDapproval');
      const pnrId = urlParams.get('PnrID');

      setDynamicData({
        transId,
        transactionToken,
        companyRef,
        ccdApproval,
        pnrId,
      });
    };


      const storedRef = localStorage.getItem('paymentRef');
      console.log('Payment reference ',storedRef);
    if (storedRef) {
      payment.createPaymentSuccess(storedRef);
    } else {
      console.error('Payment reference not found in storage');
    }


    extractDataFromURL();
  
    Swal.fire({
      icon: "success",
      title: "Payment successful!",
      text: "Click OK to view details.",
      confirmButtonColor: "#000000",
      background: "#ffffff",
    });

  }, []);

  return (

    <div className="bg-black">
      <header className="flex flex-col md:flex-row items-center justify-between md:pl-60 md:pr-60">

        <div className="flex items-center mb-2 md:mb-0">
          <img src={logo} alt="Your Company" width="200" height="250" />
        </div>


        <div className="text-2xl font-bold md:text-left md:mx-4 text-white">
          Payment Status
        </div>


      </header>


      <div className="privacy-policy p-8 text-white mb-8 text-center">
        <div className="privacy-policy-header mb-8">


          <p className="text-xl font-semibold text-green-500 pb-10">
            Payment success! Thank you for your payment.
          </p>

          {/* Display the extracted data in cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-black">
            <PaymentCard label="Transaction ID" value={dynamicData.transId} />
            <PaymentCard
              label="Transaction Token"
              value={dynamicData.transactionToken}
            />
            <PaymentCard
              label="Company Reference"
              value={dynamicData.companyRef}
            />
            <PaymentCard label="CCD Approval" value={dynamicData.ccdApproval} />
            <PaymentCard label="PNR ID" value={dynamicData.pnrId} />
          </div>
        </div>
      </div>



      <div className=" md:pl-48 md:pr-48 pt-10">

      </div>
    </div>



  );
};

export default DPOSuccessRedirect;



