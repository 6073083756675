import React from "react";
import logo from "../assets/images/image 8.png";
import { Link } from "react-router-dom";

const LandingPageFooter = () => {
  return (
    <footer class="text-center text-neutral-600 dark:bg-neutral-600 dark:text-neutral-200 lg:text-left">
      <div class="mx-6 py-10 text-center md:text-left">
        <div class="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          <div class="">
            <h6 class="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
              <div class="">
                <Link to="/">
                  <img
                    class="mx-auto h-auto w-24"
                    src={logo}
                    alt="Your Company"
                  />
                </Link>
              </div>
            </h6>
            {/* <p>
          Here you can use rows and columns to organize your footer
          content. Lorem ipsum dolor sit amet, consectetur adipisicing
          elit.
        </p> */}
          </div>
          {/* <div class="">
        <h6
          class="mb-4 flex justify-center text-white font-semibold uppercase md:justify-start">
          Navigation
        </h6>
        <p class="mb-4">
          <a href="#!" class="text-neutral-600 dark:text-neutral-200"
            >Browse Popular</a>
        </p>
        <p class="mb-4">
          <a href="#!" class="text-neutral-600 dark:text-neutral-200"
            >Browse New Release</a>
        </p>
        <p class="mb-4">
          <a href="#!" class="text-neutral-600 dark:text-neutral-200"
            >Upcoming Shows</a>
        </p>
        <p>
          <a href="#!" class="text-neutral-600 dark:text-neutral-200"
            >News</a>
        </p>
      </div> */}
          <div class="">
            <h6 class="mb-4 flex justify-center text-white font-semibold uppercase md:justify-start">
              About
            </h6>
            <p class="mb-4">
              <Link to="/privacy-policy">
                <a href="#!" >
                  Privacy Policy
                </a>
              </Link>
            </p>
            <p class="mb-4">
              <Link to="/terms-of-use">
                <a href="#!" >
                  Terms of Use
                </a>
              </Link>
            </p>

            <p class="mb-4">
              <Link to="/help">
                <a href="#!" class="text-neutral-600 dark:text-neutral-200">
                  Help
                </a>
              </Link>
            </p>
            <p>
              <a href="#!" class="text-neutral-600 dark:text-neutral-200">
                FAQ
              </a>
            </p>
          </div>
          <div>
            <h6 class="mb-4 flex justify-center text-white font-bold uppercase md:justify-start">
              Account
            </h6>
            <p class="mb-4">
              <Link to="/signin">
                <a href="" class="text-neutral-600 dark:text-neutral-200">
                Login
                </a>
              </Link>
            </p>
            <p class="mb-4">
              <Link to="/signup">
                <a href="" class="text-neutral-600 dark:text-neutral-200">
                 Register
                </a>
              </Link>
            </p>
          </div>
        </div>
      </div>

      <div class="p-6 text-center dark:bg-neutral-700">
        © Static Computers Inc. All rights reserved.
      </div>
    </footer>
  );
};

export default LandingPageFooter;
