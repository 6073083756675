import React from 'react';
const VideoContainerDesktop = ({ imageUrl, imageName }) => {
  return (
    <div className="flex flex-col items-start">
      <div
        className="w-48 h-52 mb-2 mr-2 mt-2 rounded-sm"
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
        }}
      >
        <div className="w-full h-full relative  p-1 flex items-end">
          {/* Content inside the container */}
        </div>
      </div>
      <div className="h-2"></div>
      <span className="text-white text-sm">
        {imageName.length > 25 ? `${imageName.substring(0, 25)}...` : imageName}
      </span>
    </div>
  );
};

export default VideoContainerDesktop;


