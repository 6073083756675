import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from 'firebase/messaging';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBZKA9_ybyX1Eiga02Te7KtoWVWi2PJ84Y",
  authDomain: "sethnnectionsdevelopment.firebaseapp.com",
  projectId: "sethnnectionsdevelopment",
  storageBucket: "sethnnectionsdevelopment.appspot.com",
  messagingSenderId: "549057308658",
  appId: "1:549057308658:web:eee5ab33201140f80aed6b",
  measurementId: "G-5D67CNQ8SQ"
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { app, analytics, messaging };
