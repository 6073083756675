import React from 'react'

function PendingSubcription() {
  return (
    <div>
        <div className='bg-white rounded-xl w-1/2 absolute mt-40 top-full left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:mx-auto flex'>
            
            <ol class="list-decimal py-4 px-6">
              <li className='py-4'>Your request for onesmus album launch  is still being processed, please wait. </li>
              <li className='py-4'>Your request for TNM supper league Sand Music festival  is still being processed, please wait. </li>
              <li className='py-4'>Sand Music festival</li>
            </ol>
        </div>
    </div>
  )
}

export default PendingSubcription