
const KanemaRequests = {

    fetTrends: `/trends/`,
    fetTvs: `/tvs/`,
    fetEvents: `/events/`,
    fetVod: `/vods/`,

}

export default KanemaRequests;