import React from 'react'
import TopBar from './TopBar'
import ProfileNav from './ProfileNav'
import avatar from '../assets/images/e.png'
import PaymentsCard from './PaymentsCard'

const PaymentsMethod = () => {
  return (
    <div>
        <div class="card bg-white h-screen hover:shadow-none relative flex flex-col shadow-lg">
          <TopBar/>
          <div class="px-96 ml-40 profile flex m-3 ml-4 text-black absolute top-0">
            <img class="h-60 p-2 rounded-full" src={avatar} alt=""/>
            <div class="title mt-28 py-6 ml-3 font-bold flex flex-col">
              <h1>Bill Van Demister</h1>
              <h3>Vandemister0@gmail.com</h3>
            </div>
          </div>
          <div className='mt-80'></div>
          <ProfileNav/>
          <PaymentsCard/>
        </div>
    </div>
  )
}

export default PaymentsMethod