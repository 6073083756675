import HelpTopBar from '../components/HelpTopbar'
import ProductHowItWorks from "../components/HelpBody"
import Footer from '../components/Footer'

const HelpPage = () => {
  return (
    <div className='bg-zinc-900'>
      <HelpTopBar style={{ position: 'fixed', top: 0, left: 0, right: 0 }} />
      <ProductHowItWorks />
      <Footer />
    </div>
  );
};

export default HelpPage;

