import axios from 'axios';

export async function sendPutRequest(url, accessToken, body) {
  try {
    const response = await axios.put(url, body, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (error) {
    // Handle error
    console.error('Error:', error);
  }
}
