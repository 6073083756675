import React from 'react'

const PaymentsCard = () => {
        
        
    
  return (

        <div class="p-4 mx-16 text-center space-x-4">
                <span class="inline-block rounded-lg border">
                        <button>
                                <img class="rounded-lg h-40" src="https://logos-world.net/wp-content/uploads/2020/07/PayPal-Logo.png" alt="" />
                        </button>
                        
                </span>
                <span class="inline-block rounded-lg border">
                        <button>
                                <img class="rounded-lg h-40" src="https://www.logo.wine/a/logo/Airtel_Uganda/Airtel_Uganda-Logo.wine.svg" alt="" />
                        </button>
                </span>
                <span class="inline-block rounded-lg border">
                        <button>
                                <img class="rounded-lg h-40" src="https://lh3.googleusercontent.com/eWz2ZWA-u40apVFyN2xCGmgQWbEB3QOENR3Q9Jukko_8ZLxi3I-hRUPNE_HDUUpYBe51" alt="" />
                        </button>
                </span>
                
        </div> 
  )
}

export default PaymentsCard