import React from 'react'

const SubscribedEvents = () => {
  return (
    <div>
        <div className='bg-white py-2 px-2 w-1/2 rounded-xl absolute mt-40 top-full left-1/2 transform -translate-y-1/2 -translate-x-1/2'>
        <div class="flex flex-col">
          <div class="-m-1.5 overflow-x-auto">
            <div class="p-1.5 min-w-full inline-block align-middle">
              <div class="overflow-hidden">
                <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className='bg-black text-white rounded'>
                    <tr>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase">Event</th>
                      <th scope="col" class="px-6 py-3 text-left text-xs font-medium uppercase"></th>
                      <th scope="col" class="px-6 py-3 text-right text-xs font-medium uppercase">Due Date</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Joe Black</td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button>
                          <img src="https://cdn-icons-png.flaticon.com/512/992/992651.png" className='h-6' alt="" />
                        </button>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800 dark:text-gray-200">16:00, 31/4/2023</td>
                    </tr>
                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Joe Black</td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button>
                          <img src="https://cdn-icons-png.flaticon.com/512/992/992651.png" className='h-6' alt="" />
                        </button>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800 dark:text-gray-200">16:00, 31/4/2023</td>
                    </tr>

                    <tr>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-200">Joe Black</td>
                      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <button>
                          <img src="https://cdn-icons-png.flaticon.com/512/992/992651.png" className='h-6' alt="" />
                        </button>
                      </td>
                      <td class="px-6 py-4 whitespace-nowrap text-right text-sm text-gray-800 dark:text-gray-200">16:00, 31/4/2023</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default SubscribedEvents