import Navbar from "../components/Navbar";
import Jumbotron from "../components/Jumbotron";
import HorizontalCards from "../components/LiveTVs";
import TendingHorizontal from "../components/TrendingHorizontalVideos";
import VideoOnDemand from "../components/VideosOnDemand";
import LiveEvents from "../components/LiveEvents";
import Upcoming from "../components/Upcoming";
import Footer from "../components/Footer";
import { useNavigate, Link } from "react-router-dom";
import BottomNav from "../components/BottomNav";
import AuthService from "../services/auth.service";
import JWTCheck from "../services/auth.jwt";
import authHeader from "../services/auth-header"; // Replace with thconst headers = authHeader();e actual path to the file containing the exported function
import React, { useState, useEffect } from "react";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
// Import your AuthService here
import KGIF from "../assets/gif/kanemaonline.gif";
import FeaturedMovie from "../components/FeaturedMovie";

import { useDispatch, useSelector } from "react-redux";
import { fetchLoadDataThunk } from "../api/loadingDataThunks";
import { setLoadData } from "../actions/loadingActions";

// Create a reusable section component
const Section = ({ title, linkTo, children }) => {
  return (
    <div className="px-2 sm:px-1">
      <div className="flex md:justify-between justify-between mt-2">
        <a
          href="#"
          className="text-white hover:text-gray-600 md:text-xl font-urbanist"
        >
          {title}
        </a>
        <Link to={linkTo}>
          <a href="#" className="text-white hover:text-gray-600">
            All {title}s
          </a>
        </Link>
      </div>
      <hr className="md:w-28 w-24 h-1 my-0 bg-red-600 border-0 md:my-0 dark:bg-red-600" />
      <div className="mb-4"></div>
      {children}
    </div>
  );
};

const Homepage = () => {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  const fetchTrendingUrl = KanemaRequests.fetTrends;
  const [trendingVideos, setTrendingVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 760);
  const dispatch = useDispatch();
  const loadingDataState = useSelector((state) => state.loadingData);


  const category = "tvs";

  useEffect(() => {
    if (!currentUser) {
      navigate("/signin");
    }
  }, [currentUser, navigate]);


  useEffect(() => {
    // Dispatch the thunk to fetch loading data
    dispatch(fetchLoadDataThunk(fetchTrendingUrl, authHeader()));
  }, [dispatch, fetchTrendingUrl]);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 760);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Render loading spinner if data is still loading
  if (loadingDataState.loading) {
    // Render loading spinner
    return (
      <div className="text-center mt-4 justify-center items-center">
        <img src={KGIF} alt="Loading..." className="w-100 h-100 mx-auto" />
      </div>
    );
  }


  return (
    <div className="text-white ">
      <Navbar />
      <div className="md:hidden">
        <BottomNav />
      </div>
      <div className="mb-20">
        {isMobile ? <Jumbotron /> : <FeaturedMovie />}
      </div>
      <Section title="Trending" linkTo="/MoreTrending">
        <TendingHorizontal category="trending" />
      </Section>
      <Section title="Live TV" linkTo="/LiveTV">
        <HorizontalCards />
      </Section>
      <Section title="Live Event" linkTo="/LiveEvents">
        <LiveEvents category="events" />
      </Section>
      <Section title="Video" linkTo="/Videos">
        <VideoOnDemand />
      </Section>
      <hr className="h-px my-8 bg-white border-0 dark:bg-white" />
      <Footer />
    </div>
  );
};

export default Homepage;
