import VerifyPasswordTopBar from "../components/VerifyPasswordTopBar";
import VerifyPasswordBody from "../components/verifyPasswordBody";
import VerifiedEmailFailedody from "../components/VerifiedEmailFailedody";
import Footer from "../components/Footer";
import "./VerifyEmail.css";
import { useLocation } from 'react-router-dom';


import { useEffect ,useState} from "react";
import axios from "axios";
const VerifyEmail = () => {
const currentURL = window.location.href;

const regex = /token=([^&]+)/;
const match = currentURL.match(regex);

// Check if a match is found and extract the token value
let token = "";
if (match && match.length > 1) {
  token = match[1];
}

console.log("The extracted token ",token);// Extract the token from the path

  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const verifyToken = async () => {
      
      try {
        const response = await axios.get(`https://kanemaonline.com/auth/verify-email/${token}`);
        setStatus(response.data.status);
        setMessage(response.data.message);
        console.log(response.data.message);
        console.log(response.data.status);
      } catch (error) {
        console.error("Error verifying email:", error);
      }
    };

    verifyToken();
  }, [token]);

  return (
   <div className='bg-zinc-900'>
      <VerifyPasswordTopBar style={{ position: 'fixed', top: 0, left: 0, right: 0 }} />
    
       {status === 'unsuccesful' && (
       <div className='container'>
         <VerifiedEmailFailedody />
      </div>
        
      )}
      {status === 'success' && (
         <div className='container'>
         <VerifyPasswordBody />
      </div>
      )}
      
      <Footer />
    </div>
  );
};

export default VerifyEmail;


// const verifyEmail = async () => {
//   try {
//     const response = await axios.get(`https://kanemaonline.com/auth/verify-email/${token}`);
//     if (response.status === 200) {
//       const data = response.data;
//       if (data.status === 'success') {
//         // Set verification successful state to true
//         setVerificationSuccessful(true);
//         // Show success alert
//         Swal.fire({
//           icon: 'success',
//           title: 'Email Verification Successful!',
//           text: data.message,
//           confirmButtonColor: '#61C461',
//         });
//       } else {
//         // Show error alert
//         Swal.fire({
//           icon: 'error',
//           title: 'Email Verification Failed',
//           text: 'Please try again later.',
//           confirmButtonColor: '#61C461',
//         });
//       }
//     } else {
//       // Show error alert
//       Swal.fire({
//         icon: 'error',
//         title: 'Email Verification Failed',
//         text: 'Please try again later.',
//         confirmButtonColor: '#61C461',
//       });
//     }
//   } catch (error) {
//     console.error('Error verifying email:', error);
//     // Show error alert
//     Swal.fire({
//       icon: 'error',
//       title: 'An error occurred',
//       text: 'Please try again later.',
//       confirmButtonColor: '#61C461',
//     });
//   }
// };




// const token = new URLSearchParams(window.location.href).get("token");
// console.log("kkkkvjghgcfchhc"+token);

// const VerifyEmail = () => {
 
//   const [verificationSuccessful, setVerificationSuccessful] = useState(false);


//   useEffect(async () => {
//     // Make the GET request to the server.
//     const response = await axios.get(
//       "https://kanemaonline.com/auth/verify-email/" + token,
//       {
//         method: "GET",
//       }
//     );

//     // Handle the response from the server.
//     if (response.status === 200) {
//       // The email address was verified successfully.
//       setVerificationSuccessful(true);
//     } else {
//       // The email address was not verified successfully.
//       // Handle the error.
//     }
//   }, [token]);

//   return (
//     <div className="bg-zinc-900">
//       <VerifyPasswordTopBar style={{ position: "fixed", top: 0, left: 0, right: 0 }} />
//       <div className="container">
//         {verificationSuccessful ? (
//           <div>
//             <VerifyPasswordBody />
//           </div>
//         ) : (
//           <div>
//             <h1>Email verification failed.</h1>
//           </div>
//         )}
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default VerifyEmail;
