// Thunk action creators
export const setLoadData = (data) => ({
  type: "SET_LOADING_DATA",
  payload: data,
});

export const fetchLoadDataStart = () => ({
  type: "FETCH_LOADING_DATA_START",
});

export const fetchLoadDataSuccess = (data) => ({
  type: "FETCH_LOADING_DATA_SUCCESS",
  payload: data,
});

export const fetchLoadDataFailure = (error) => ({
  type: "FETCH_LOADING_DATA_FAILURE",
  payload: error,
});
