import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import homeImage from '../assets/images/home.png';
import liveTVImage from '../assets/images/live-tv.png';
import liveEventsImage from '../assets/images/live-events.png';
import videosImage from '../assets/images/videos.png';

const BottomNav = () => {
  return (
    <>
      <div className="fixed bottom-0 left-0 z-50 w-full h-16 bg-black border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
        <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          <Link to="/homepage">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-black dark:hover:bg-gray-800 group"
            >
              <img
                src={homeImage}
                alt="Home"
                className="w-15 h-10 mb-1 object-contain text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500"
              />
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500">
                Home
              </span>
            </button>
          </Link>

          <Link to="/LiveTV">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-black dark:hover:bg-gray-800 group"
            >
              <img
                src={liveTVImage}
                alt="Live TV"
                className="w-15 h-10  mb-1 object-contain text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500"
              />
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500">
                Live TV
              </span>
            </button>
          </Link>

          <Link to="/LiveEvents">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-black dark:hover:bg-gray-800 group"
            >
              <img
                src={liveEventsImage}
                alt="Events"
                className="w-15 h-10  mb-1 object-contain text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500"
              />
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500">
                Events
              </span>
            </button>
          </Link>

          <Link to="/Videos">
            <button
              type="button"
              className="inline-flex flex-col items-center justify-center px-5 hover:bg-black dark:hover:bg-gray-800 group"
            >
              <img
                src={videosImage}
                alt="Videos"
                className="w-14 h-10 mb-1 object-contain text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500"
              />
              <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-white dark:group-hover:text-blue-500">
                Videos
              </span>
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BottomNav;
