import React, { useState, useEffect } from "react";
import KanemaRequests from "../kanemarequests";
import axios from "../Kanemaxios";
import { Link } from "react-router-dom";
import authHeader from '../services/auth-header'; // Replace with thconst headers = authHeader();e actual path to the file containing the exported function
import viewCountService from "../services/viewCountService"; // Import the service
// Import other necessary dependencies

function Jumbotron() {
  const fetchTrendingUrl = KanemaRequests.fetTrends;
  const [trendingVideos, setTrendingVideos] = useState([]);
  const category = "trends";

  useEffect(() => {
    async function fetchTrendingData() {
      try {
        const headers = authHeader();
        const response = await axios.get(fetchTrendingUrl, { headers });
        const videos = response.data;

        let filteredVideos = videos.filter(video => (
          video.status.lifetime > 0 && video.status.type === "normal"
        ));

        const liveVideos = videos.filter(video => video.status.type === "live");
        if (liveVideos.length > 0) {
          filteredVideos = liveVideos;
        }

        if (filteredVideos.length > 0) {
          const firstVideo = filteredVideos[0];
          setTrendingVideos([firstVideo]);
        }
      } catch (error) {
        console.error("Error fetching trending data:", error);
      }
    }

    fetchTrendingData();
  }, [fetchTrendingUrl]);

  const handleLinkClick = async (id) => {
    try {
      await viewCountService.updateViewCount(category, id);

      if (trendingVideos.length > 0 && !trendingVideos[0].status.publish) {
        alert("This video is not published yet.");
      }
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };

  const imageUrl = trendingVideos[0]?.thumb_nail || "";

  const altText = trendingVideos.length > 0 ? trendingVideos[0].name : "";

  return (
    <div className="w-screen h-100 relative">
      <img
        src={imageUrl}
        className="w-full h-auto object-contain"
        alt={altText}
      />

      <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-30"></div>

      <div className="absolute left-0 bottom-0 p-4 md:p-8 text-white">
        <div className="max-w-screen-md">
          <h1 className="mb-2 md:mb-4 text-2xl md:text-4xl font-bold text-white">
            {altText}
          </h1>

          <div className="flex flex-wrap">
            {trendingVideos.length > 0 && trendingVideos[0].status.publish ? (
              <Link
                to={`/currentVideo?name=${trendingVideos[0]?.name || ""}&id=${trendingVideos[0]?.id || ""}&url=${trendingVideos[0]?.stream_key || ""}&category=${category}`}
                onClick={() => handleLinkClick(trendingVideos[0]?.id || "")}
                className="rounded inline-flex md:mr-2 justify-center items-center md:py-3 md:px-5 px-2 text-base md:text-xl font-bold text-white md:rounded-full bg-red-600 hover:bg-red-500 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900"
              >
                <svg
                  className="w-6 h-6 dark:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  ></path>
                </svg>
                Play
              </Link>
            ) : (
              <div className="rounded inline-flex md:mr-2 justify-center items-center md:py-3 md:px-5 px-2 text-base md:text-xl font-bold text-white md:rounded-full bg-red-600 opacity-50 cursor-not-allowed">
                <svg
                  className="w-6 h-6 dark:text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  ></path>
                </svg>
                Not Published
              </div>
            )}

            <div className="flex ">
              <h2 className="rounded inline-flex justify-center items-center px-1 md:py-3 md:px-5 text-base md:text-xl font-bold text-black bg-white md:rounded-full border border-white focus:ring-4 focus:ring-gray-400">
                Trending
              </h2>
              {/* Additional elements */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Jumbotron;


// function Jumbotron() {
//   const fectTrendingUrl = KanemaRequests.fetTrends;
//   const [trendingVideos, setTrendingVideos] = useState([]);
//   const category = "trends";

//   useEffect(() => {
//     async function fetchTrendingData() {
//       const headers = authHeader();
//       const response = await axios.get(fectTrendingUrl, { headers });
//       const videos = response.data;

//       // Filter the videos based on the specified conditions
//       let filteredVideos = videos.filter(video => {
//         return video.status.lifetime > 0 && video.status.type === "normal";
//       });

//       // Check if there are any "live" videos
//       const liveVideos = videos.filter(video => video.status.type === "live");
//       if (liveVideos.length > 0) {
//         filteredVideos = liveVideos; // Override with live videos
//       }

//       // Sort the filtered videos based on views in descending order
//       filteredVideos.sort((a, b) => b.views - a.views);

//       if (filteredVideos.length > 0) {
//         const firstVideo = filteredVideos[0];
//         setTrendingVideos([firstVideo]);
//       }
//     }

//     fetchTrendingData();
//   }, [fectTrendingUrl]);


//   //console.log(trendingVideos.cover_url)

//   //console.log(trendingVideos[0]);

//   const handleLinkClick = async (id) => {
//     try {
//       // Call the updateViewCount function from the service
//       await viewCountService.updateViewCount(category, id);

//     } catch (error) {
//       // Handle errors if needed
//       console.error("Error updating view count:", error);
//     }
//   };
//   const imageUrl = trendingVideos[0] ? trendingVideos[0].thumb_nail : "";
//   return (
 

//     <Link
//       onClick={() => handleLinkClick(trendingVideos[0] ? trendingVideos[0]._id : "")}
//       to={`/currentVideo?name=${trendingVideos[0] ? trendingVideos[0].name : ""}&id=${trendingVideos[0] ? trendingVideos[0]._id : ""}&url=${trendingVideos[0]
//         ? `${trendingVideos[0].stream_key}`
//         : ""}&category=${category}`}

//     >
//       <div className="w-screen h-100 relative">
//         {/* Place the image inside the container */}
//         <img
//           src={imageUrl}
//           className="w-full h-full object-contain"
//           alt="..."
//         />

//         {/* Dark gradient overlay */}
//         <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-30"></div>

//         {/* Content columns */}
//         <div className="absolute left-0 bottom-0 p-4 md:p-8 text-white">
//           <div className="max-w-screen-md">
//             <h1
//               className="mb-2 md:mb-4 text-2xl md:text-4xl font-bold text-white"
//               style={{ opacity: 1.0 }}
//             >
//               {trendingVideos[0] ? trendingVideos[0].name : ""}
//             </h1>

//             {/* Rest of the code */}

//             <div className="flex flex-wrap">
//               <a
//                 href="#"
//                 className="rounded inline-flex md:mr-2 justify-center items-center md:py-3 md:px-5 px-2 text-base md:text-xl font-bold text-white md:rounded-full bg-red-600 hover:bg-red-500 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900"
//               >
//                 {/* SVG Icon */}
//                 <svg
//                   className="w-6 h-6 dark:text-white"
//                   fill="none"
//                   stroke="currentColor"
//                   viewBox="0 0 24 24"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
//                   ></path>
//                 </svg>
//                 Play
//               </a>
//               <div className="flex ">
//                 <h2 className="rounded inline-flex justify-center items-center px-1 md:py-3 md:px-5 text-base md:text-xl font-bold text-black bg-white md:rounded-full border border-white focus:ring-4 focus:ring-gray-400">
//                   Trending
//                 </h2>
//                 {/* Rest of the elements */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Link>

//   );
// }

// export default Jumbotron;










  // <Link
    //   to={`/currentVideo?name=${trendingVideos[0] ? trendingVideos[0].name : ""}&id=${trendingVideos[0] ? trendingVideos[0]._id:""}&url=${trendingVideos[0]
    //         ? `${trendingVideos[0].stream_key}`
    //         : ""}&category=${category}`}
    // >
    //   <section
    //     className="md:mx-8 md:py-12 rounded-xl bg-center bg-cover bg-no-repeat"
    //     style={{
    //       backgroundImage: trendingVideos[0]
    //         ? `url(${trendingVideos[0].thumb_nail})`
    //         : "",
    //       backgroundBlendMode: "multiply",
    //       opacity: "1.0",
    //       borderRadius: "8px",
    //     }}
    //   >
    //     <div className="px-4 max-w-screen md:py-20 py-20">
    //       <div className="mt-20"></div>

    //       <h1
    //         className="mb-4 text-4xl font-bold text-white md:text-3xl lg:text-4xl"
    //         style={{ opacity: 1.0 }}
    //       >
    //         {trendingVideos[0] ? trendingVideos[0].name : ""}
    //       </h1>
    //       {/* Rest of the code */}

    //       <div className="flex flex-wrap">
    //         <a
    //           href="#"
    //           className="rounded inline-flex md:mr-2 justify-center items-center md:py-3 md:px-5 px-2 text-base md:font-bold text-center text-white md:rounded-full bg-red-600 hover:bg-red-500 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900"
    //         >
    //           {/* SVG Icon */}
    //           <svg
    //             className="w-6 h-6 dark:text-white"
    //             fill="none"
    //             stroke="currentColor"
    //             viewBox="0 0 24 24"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               strokeLinecap="round"
    //               strokeLinejoin="round"
    //               strokeWidth="2"
    //               d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
    //             ></path>
    //           </svg>
    //           Play
    //         </a>
    //         <div className="flex">
    //           <h2 className="rounded inline-flex justify-center items-center px-1 md:py-3 md:px-5 text-base md:font-bold text-center text-black bg-white md:rounded-full border border-white focus:ring-4 focus:ring-gray-400">
    //             Trending
    //           </h2>
    //           {/* Rest of the elements */}
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </Link>