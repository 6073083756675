import * as React from "react";
import Verifylogo from "../assets/images/verifypass.png";
import EmailandPhone from "../assets/images/phone and email.png";
import SocialMedia from "../assets/images/social.png";
import Postal from "../assets/images/postal.png";
// import Contacts from "../assets/images/contacts.png";
// // import helplogo from "../assets/images/help.png";
// import ImageListItem from '@mui/material/ImageListItem';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "../components/Button";
import Typography from "../components/Typography";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary.main",
  fontWeight: "medium",
};

const image = {
  borderRadius: "50%",
  width: "180px",
  height: "180px",
  margin: "5px", // Add margin
  padding: "5px", // Add padding
};

const imageStyle = {
  borderRadius: "50%",
  width: "80px",
  height: "80px",
  margin: "5px", // Add margin
  padding: "5px", // Add padding
};

function VerifyPasswordBody() {
  return (
    <div>
      <div className="block">
        <Box
          component="section"
          sx={{ display: "flex", overflow: "hidden", position: "relative" }}
        >
          <Container
            sx={{
              mt: 10,
              mb: 15,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component="img" src={Verifylogo} alt="graph" sx={image} />

            <Typography
              variant="h5"
              align="center"
              sx={{
                mb: 14,
                color: "white",
                fontSize: "inherit",
                display: "flex",
                alignItems: "center",
              }}
            >
Thank you for registering with us; you may now sign in with your confirmed email address.            </Typography>
           
          </Container>
        </Box>
      </div>
    </div>
  );
}

export default VerifyPasswordBody;
