import axios from "axios";

import {
  setLoadData,
  fetchLoadDataStart,
  fetchLoadDataSuccess,
  fetchLoadDataFailure,
} from "../actions/loadingActions";

export const fetchLoadDataThunk = (url, headers) => async (dispatch) => {
  dispatch(fetchLoadDataStart());

  try {
    const response = await axios.get(url, { headers });
    const data = response.data;
    dispatch(fetchLoadDataSuccess(data));
  } catch (error) {
    console.error("Error fetching loading data:", error);
    dispatch(fetchLoadDataFailure(error.message));
  }
};


export const fetchLoadData = (url, headers) => async (dispatch) => {
  dispatch(fetchLoadDataStart());

  try {
    const response = await axios.get(url, { headers });
    const data = response.data;
    dispatch(fetchLoadDataSuccess(data));
  } catch (error) {
    console.error("Error fetching loading data:", error);
    dispatch(fetchLoadDataFailure(error.message));
  }
};

