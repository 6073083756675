import React, { useState } from "react";
import logo from "../assets/images/image 8.png";
import background from "../assets/images/background.png";
import { useNavigate, Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import AuthService from "../services/auth.service";


function Signin() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false)
  const [registrationStatus, setRegistrationStatus] = useState("");


  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate('/homepage');
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);

  try {
    const loginResponse = await AuthService.login(email, password);

    if (loginResponse.status === "success") {
      // Redirect to the homepage upon successful login
      goToHomePage();
    } else if (loginResponse.status === "unsuccessful") {
      if (loginResponse.message === "Incorrect password") {
        setRegistrationStatus("Incorrect password. Please try again.");
      } else if (loginResponse.message === "Please confirm your email") {
        setRegistrationStatus("Please confirm your email before logging in.");
      } else {
        setRegistrationStatus("Login unsuccessful. Please try again.");
      }
    } else {
      setRegistrationStatus("Login unsuccessful. Please try again.");
    }
  } catch (error) {
    console.error("Login failed:", error);
    setRegistrationStatus("Login unsuccessful. Please try again.");
  } finally {
    setLoading(false);
  };
};



  return (
    <div className="w-full h-full">
      <img
        className="absolute h-full w-full object-cover"
        src={background}
      />
      <div className="bg-black/80 fixed top-0 left-0 w-full h-full">
        <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
           <div className="md:mx-auto md:w-full md:max-w-md max-w-md">
          <Link to="/">
            <img
              className="mx-auto md:h-auto md:w-80 w-1/2"
              src={logo}
              alt="Kanema Online"
              // style={{ maxWidth: '100%', maxHeight: '80px' }}
            />
          </Link>
        </div>
          <div class="bg-white md:px-6 px-3 md:py-12 py-6 mt-10 sm:mx-auto sm:w-full sm:max-w-sm rounded-xl">
            <form
              class="space-y-6"
              //   action="#"
              //   method="POST"
              onSubmit={handleSubmit}
            >
              <div class="relative w-full">
                <div class="mt-10">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16"> <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/> </svg>
                    </div> 
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    placeholder="Enter your Email address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    class="pl-10 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="relative w-full">
                <div class="mt-2">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-lock" viewBox="0 0 16 16"> <path  d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"/> </svg>
                    </div> 
                  <input
                    id="password"
                    name="password"
                    type={visible ? "text" : "password"}
                    placeholder="Enter your password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    class="pl-10 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6"
                  />
                  <div onClick={() => setVisible(!visible)} class="absolute inset-y-0 right-0 flex items-center pr-3">
                    {visible ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-slash-fill"
                      viewBox="0 0 16 16"
                    >
                      {" "}
                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />{" "}
                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />{" "}
                    </svg> */}
                  </div>
                </div>
              </div>
              {/* <div className="text-center">
                <div class="mb-4">
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    value=""
                    class="w-4 h-4 text-red-600 bg-red-600 border-red-600 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-red-600 focus:ring-2 dark:bg-red-600 dark:border-red-600"
                  />
                  <label
                    for="default-checkbox"
                    class="ml-2 text-sm text-gray-500 dark:text-gray-300"
                  >
                    Save Password
                  </label>
                </div>
              </div> */}

              <div>
                {registrationStatus && <p>{registrationStatus}</p>}
                <button
                  disabled={loading}
                  type="submit"
                  class="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
                >
                  {loading ? "Loading..." : " Login"}
                </button>
              </div>
              <div class="text-sm text-center">
                <Link to="/ForgotPassword">
                  <a
                    href="#"
                    class="font-semibold text-red-600 hover:text-red-500"
                  >
                    Forgot password?&nbsp;
                  </a>
                </Link>
              </div>
            </form>
            <p class="mt-8 text-center text-sm text-black">
              Don't have an account?
              <Link to="/signup">
                <a
                  href="#"
                  class="font-semibold leading-6 text-red-600 hover:text-red-500"
                >
                  &nbsp; Register
                </a>
              </Link>
            </p>
          </div>
          <div class="hidden md:block absolute mt-16 inset-y-0 right-0 text-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <a href="https://www.facebook.com/kanemaonline1"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </a>

            <a href="https://www.instagram.com/kanemaonline"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>

            <a href="https://www.twitter.com/KanemaOnline"
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
            <p className="mt-6 ">&copy; Static Computers Inc. All rights reserved.</p>
          </div>
          <div class="md:hidden block md:absolute mt-16 md:inset-y-0 md:right-0 text-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <button
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
              </svg>
            </button>

            <button
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </button>

            <button
              type="button"
              data-te-ripple-init
              data-te-ripple-color="light"
              class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </button>
            <p className="mt-6 text-white">&copy; Static Computers Inc. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
    // <div className="text-center bg-signin bg-repeat h-screen">
    //     <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
    //         <div class="sm:mx-auto sm:w-full sm:max-w-sm">
    //             <img class="mx-auto h-auto w-92" src={logo} alt="Your Company" />

    //         </div>
    //         <div class="bg-white px-6 py-12 mt-10 sm:mx-auto sm:w-full sm:max-w-sm rounded-xl">
    //             <form class="space-y-6" action="#" method="POST">
    //                 <div>
    //                     <div class="mt-10">
    //                     <input id="email" name="email" type="email" autocomplete="email" placeholder='Email address' required class="pl-4 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6" />
    //                     </div>
    //                 </div>
    //                 <div>
    //                     <div class="mt-2">
    //                     <input id="password" name="password" type="password" placeholder='Password' required class="pl-4 block w-full rounded-md border-0 py-1.5 text-black shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-black sm:text-sm sm:leading-6" />
    //                     </div>
    //                 </div>
    //                 <div className='text-center'>
    //                     <div class="mb-4">
    //                         <input id="default-checkbox" type="checkbox" value="" class="w-4 h-4 text-red-600 bg-red-600 border-red-600 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-red-600 focus:ring-2 dark:bg-red-600 dark:border-red-600" />
    //                         <label for="default-checkbox" class="ml-2 text-sm text-gray-500 dark:text-gray-300">Show Password</label>
    //                     </div>
    //                 </div>

    //                 <div>
    //                     <button type="submit" class="flex w-full justify-center rounded-md bg-black px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black">Log In</button>
    //                 </div>
    //                 <div class="text-sm text-center">
    //                         <a href="#" class="font-semibold text-red-600 hover:text-red-500">Forgot password?</a>
    //                 </div>
    //             </form>
    //             <p class="mt-8 text-center text-sm text-black">
    //             Don't have an account?
    //             <a href="#" class="font-semibold leading-6 text-red-600 hover:text-red-500">Sign Up</a>
    //             </p>
    //         </div>
    //         <div class="absolute mt-28 inset-y-0 right-0 items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
    //             <button
    //             type="button"
    //             data-te-ripple-init
    //             data-te-ripple-color="light"
    //             class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
    //             >
    //             <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 class="h-4 w-4"
    //                 fill="currentColor"
    //                 viewBox="0 0 24 24">
    //                 <path
    //                 d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
    //             </svg>
    //             </button>

    //             <button
    //             type="button"
    //             data-te-ripple-init
    //             data-te-ripple-color="light"
    //             class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
    //             >
    //             <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 class="h-4 w-4"
    //                 fill="currentColor"
    //                 viewBox="0 0 24 24">
    //                 <path
    //                 d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
    //             </svg>
    //             </button>

    //             <button
    //             type="button"
    //             data-te-ripple-init
    //             data-te-ripple-color="light"
    //             class="mb-2 inline-block m-3 bg-white rounded-full p-3 text-xs font-medium uppercase leading-normal text-black shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
    //             >
    //             <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 class="h-4 w-4"
    //                 fill="currentColor"
    //                 viewBox="0 0 24 24">
    //                 <path
    //                 d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
    //             </svg>
    //             </button>
    //         </div>
    //     </div>
    // </div>
  );
}

export default Signin;
