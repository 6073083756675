import * as React from "react";

const TabSelector = ({
  isActive,
  children,
  onClick,
}) => (
  <button
    className={`mr-8 group inline-flex items-center px-4 py-4 border-b-2 font-medium text-sm leading-5 cursor-pointer whitespace-nowrap ${
      isActive
        ? "bg-[#313131] text-white border-[#313131] focus:outline-none focus:text-white focus:border-[#313131] rounded-t-md rounded-l-md rounded-r-md"
        : "border-transparent text-white hover:text-gray-600 hover:border-gray-300 focus:text-gray-600 focus:border-gray-300"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);

export default TabSelector;
