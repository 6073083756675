import axios from "axios";
//import { useNavigate, Link } from "react-router-dom";
//the main auth domain
const API_URL = "https://kanemaonline.com/auth/";

//the signup function
const signup = async (email, phone, password) => {
  const response = await axios.post(API_URL + "/register", {
    email,
    phone,
    password,
  });
  if (response.data.accessToken) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};
const login = async (email, password) => {
  const headers = {
    // Replace with your desired link
    "X-link": "website.kanemaonline.com",
  };

  try {
    const response = await axios.post(
      API_URL + "/login",
      {
        email,
        password,
      },
      { headers }
    );

    // console.log("response",response.data);

    if (response.data.status === "success") {
      if (response.data.tk) {
        // Store data in local storage upon successful login
        const { accessToken, refresh_token } = response.data.tk;
        localStorage.setItem("user", response.data.user);
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("email", email);

        return { status: "success", message: "Login successful" };
      } else {
        console.error("Login failed: Access token is undefined");
        throw new Error("Login failed: Access token is undefined");
      }
    } else if (response.data.message === "Password is incorrect") {
      return { status: "unsuccessful", message: "Incorrect password" };
    } else if (response.data.message === "Please confirm your email") {
      return { status: "unsuccessful", message: "Please confirm your email" };
    }


  } catch (error) {
    // Handle login error
    console.error("Login failed:", error);
    throw error;
  }
};


const fogotpassword = async (token3, password) => {
  console.log("The forgot password token  is ==== ", token3);
  const response = await axios.post(API_URL + "/reset-password", {
    token3,
    password,
  });

  return response;
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return localStorage.getItem("user");
};

const authService = {
  signup,
  login,
  logout,
  getCurrentUser,
  fogotpassword,
};
export default authService;
