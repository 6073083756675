import React, { useEffect, useState } from "react";
import axios from "axios";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import VideoContainer from "../components/VideoContainerMobile";
import VideoContainerDesktop from "../components/VideoContainerDesktop";
import viewCountService from "../services/viewCountService";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import config from "../api/config";

const TendingHorizontal = ({ category }) => {
  const [trendingVideos, setTrendingVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accessToken = localStorage.getItem("accessToken");
  const fetchTrendingUrl = `${config.apiBaseUrl}/trends`;

  const slideToLeft = () => {
    var slider = document.getElementById("Trendslider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideToRight = () => {
    var slider = document.getElementById("Trendslider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(fetchTrendingUrl, {
          headers: {
            Authorization: accessToken,
          },
        });

        let data = response.data;

        // Sort the data based on views (highest to lowest)
        data.sort((a, b) => b.views - a.views);

        // Filter videos with visibility true and lifetime more than zero
        data = data.filter(
          (video) => video.status.visibility && video.status.lifetime > 0
        );

        setTrendingVideos(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching trends data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [fetchTrendingUrl, accessToken]);

  const handleLinkClick = async (id, isPublished) => {
    try {
      if (!isPublished) {
        await Swal.fire({
          icon: "warning",
          title: "Coming soon!",
          text: "The video you are trying to play is not yet published.",
          customClass: {
            container: "bg-black text-white",
            title: "text-black",
            content: "text-black",
            confirmButton: "bg-black text-white hover:bg-gray-700",
          },
          confirmButtonColor: "#000000",
          width: 400,
          height: 20,
          animation: false,
        });

        return;
      }

      await viewCountService.updateViewCount(category, id);
    } catch (error) {
      console.error("Error updating view count:", error);
    }
  };

  return (
    <div className="">
      <div className="flex items-center bg-black m-auto p-auto">
        <MdChevronLeft onClick={slideToLeft} size={160} />
        <div
          id="Trendslider"
          className="flex overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide pb-10"
        >
          {loading && <p>Loading...</p>}
          {error && <p>Error: {error}</p>}
          {Array.isArray(trendingVideos) &&
            trendingVideos.map((trendingVideo) => (
              <div className="flex flex-nowrap" key={trendingVideo._id}>
                <div>
                  {trendingVideo.status.publish ? (
                    <Link
                      onClick={() =>
                        handleLinkClick(
                          trendingVideo._id,
                          trendingVideo.status.publish
                        )
                      }
                      to={`/currentVideo?name=${trendingVideo.name}&id=${
                        trendingVideo._id
                      }&category=${"trends"}&url=${trendingVideo.stream_key}`}
                    >
                      <div className="block sm:hidden">
                        <VideoContainer
                          imageUrl={trendingVideo.thumb_nail}
                          imageName={trendingVideo.name}
                        />
                      </div>
                      <div className="hidden sm:block">
                        <VideoContainerDesktop
                          imageUrl={trendingVideo.thumb_nail}
                          imageName={trendingVideo.name}
                        />
                      </div>
                    </Link>
                  ) : (
                    <div
                      onClick={() =>
                        handleLinkClick(
                          trendingVideo._id,
                          trendingVideo.status.publish
                        )
                      }
                      className="cursor-not-allowed shadow-xl  "
                    >
                      <div className="block sm:hidden">
                        <VideoContainer
                          imageUrl={trendingVideo.thumb_nail}
                          imageName={trendingVideo.name}
                        />
                      </div>
                      <div className="hidden sm:block">
                        <VideoContainerDesktop
                          imageUrl={trendingVideo.thumb_nail}
                          imageName={trendingVideo.name}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <MdChevronRight onClick={slideToRight} size={160} />
      </div>
    </div>
  );
};

export default TendingHorizontal;
