// PaymentOptionCard.js
import React from "react";

const PaymentOptionCard = ({ name, description, image, onClick  }) => (
  <div className="bg-[#8e8e8e] p-4 rounded shadow transition-transform transform hover:scale-105" onClick={onClick}>
    <img src={image} alt={name}  className="mx-auto mb-4 hover:cursor-pointer transition-transform transform hover:scale-110" style={{ maxWidth: "150px",  height:"80px" , transition: "transform 0.3s ease-in-out" }} />

  </div>
);

export default PaymentOptionCard;


