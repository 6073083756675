export const setEventData = (data) => ({
  type: "SET_EVENT_DATA",
  payload: data,
});

export const fetchEventDataStart = () => ({
  type: "FETCH_EVENT_DATA_START",
});

export const fetchEventDataSuccess = (data) => ({
  type: "FETCH_EVENT_DATA_SUCCESS",
  payload: data,
});

export const fetchEventDataFailure = (error) => ({
  type: "FETCH_EVENT_DATA_FAILURE",
  payload: error,
});
