import React from 'react';
import mdbn from '../assets/images/TV logos/mdbn.png'

const MalawiDigitalFooter = () => {
  const footerStyles = {
    width: '100%',
    height: '80px',
    backgroundColor: '#333',
  };

  const contentStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    color: '#fff',
  };

  const iconStyles = {
    fontSize: '24px',
    marginRight: '8px',
  };

  const textStyles = {
    fontSize: '18px',
    fontWeight: 'bold',
  };

  return (
    <footer style={footerStyles}>
      <div style={contentStyles}>
         <p style={textStyles}>Powered By MDBNL</p>
         <div className="md:w-16 w-8 mt-2" style={iconStyles}>
            <img class="" src={mdbn} alt=""  />
         </div>
      </div>
    </footer>
  );
};

export default MalawiDigitalFooter;


